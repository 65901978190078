import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function RequireAuth() {
    const { isAuth } = useSelector((state) => state.userReducer);
    const user = localStorage.getItem('user') === null ? null : JSON.parse(localStorage.getItem('user'));

    const location = useLocation();
    return user !== null ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
}

export default RequireAuth;
