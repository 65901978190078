import axiosClient from '../axiosClient';

const CartApi = {
    async addToCart(data) {
        const url = `/cart`;
        return axiosClient.post(url, data);
    },
    async getAllCart() {
        const url = `/cart`;
        return axiosClient.get(url);
    },
    async checkoutCart(data) {
        const url = `/orders/count-price`;
        return axiosClient.post(url, data);
    },
    async updateQuantity(data, id) {
        const url = `/cart/${id}`;
        return axiosClient.put(url, data);
    },
    async deleteProductInCart(data) {
        const url = `/cart`;
        return axiosClient.delete(url, { data });
    },
    async getShipFee(data) {
        const url = `/orders/fee`;
        return axiosClient.post(url, data);
    },
    async checkoutOrder(data) {
        const url = `/orders/check-out1`;
        return axiosClient.post(url, data);
    },
    async saveCheckedCart(data) {
        const url = `/orders/save-data-orders`;
        return axiosClient.post(url, data);
    },
    async getCheckedCart() {
        const url = `/orders/get-data-orders`;
        return axiosClient.get(url);
    },
};

export default CartApi;
