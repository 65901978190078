import axiosClient from "../axiosClient";

export const projectsApi = {
  async getAllCountry() {
    const url = `/country/`;
    return axiosClient.get(url);
  },
  async create(data) {
    const url = `/projects`;
    return axiosClient.post(url, data);
  },
  async edit(data) {
    const url = `/projects/${data.id}`;
    return axiosClient.put(url, data.body);
  },
  async delete(id) {
    const url = `/projects/${id}`;
    return axiosClient.delete(url);
  },
  async createContributor(data) {
    const url = `/contributor/`;
    return axiosClient.post(url, data);
  },
  async getAllProject() {
    const url = `/projects/`;
    return axiosClient.get(url);
  },
  async getProjectByStore(id, index, size) {
    const url = `https://api.ecommerce.smiletech.vn:8443/projects/all-paging/all?store_id=${id}&page_index=${index}&page_size=${size}`;
    return axiosClient.get(url);
  },
  async getProjectById(id) {
    const url = `/projects/${id}`;
    return axiosClient.get(url);
  },
  async getListContact(id) {
    const url = `/projects/get-project-me/${id}`;
    return axiosClient.get(url);
  },
  async getProjectAllPaging(data) {
    const url = `/projects/all-paging/all?page_index=${data.index}&page_size=${data.size}`;
    return axiosClient.get(url);
  },
  async updateContributor(data) {
    const url = `/contributor/${data.id}`;
    return axiosClient.put(url, data.body);
  },
};
