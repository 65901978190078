import axios from 'axios';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ToastPopup, { notifyError, notifySuccess } from '~/toast/toast';
import { isValid, validate } from '~/utils/validate';
import documentApi from '~/api/Document/documents.Api';
import styles from './CreateDocuments.module.scss';

const cx = classNames.bind(styles);
const { Option } = Select;

const CreateDocuments = () => {
    let formData = new FormData();
    const [, setFiles] = useState([]);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [documentData, setDocumentData] = useState({
        name: '',
        type: 3,
        category_id: '',
        ecommerce_id: 69,
        image_url: [],
    });

    const handleFilesChange = async (e) => {
        setFiles(e.target.files);
        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setFiles([]);
            setDocumentData({
                ...documentData,
                image_url: documentData.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
            notifySuccess(t('text.upload_file_success'));
        } catch (error) {
            notifyError(t('text.upload_file_failed'));
        }
    };
    const isEmpty = (
        <div className="is-empty">
            <h3>{t('text.No_material_available')}</h3>
            <input type="file" multiple id="file-upload" style={{ display: 'none' }} onChange={handleFilesChange} />
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                {t('text.add_material')}
            </label>
        </div>
    );
    const removeImage = (position) => {
        setDocumentData({
            ...documentData,
            image_url: documentData.image_url.filter((_, index) => position !== index),
        });
    };
    const isNotEmpty = (arr) => {
        return (
            <div className="image-container">
                <div className="big-image">
                    <div className="delete-image" onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={`${process.env.REACT_APP_API_URL}/${arr[0].url}`} alt="big" />
                </div>
                <div className="small-image-container">
                    {arr.map((item, index) => {
                        if (index !== 0) {
                            return (
                                <div
                                    className="small-image"
                                    key={item.url}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                    }}
                                >
                                    <div className="delete-image" onClick={() => removeImage(index)}>
                                        X
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                    <div className="small-image upload">
                        <input
                            className="item__input"
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>{t('text.add_material')}</h4>
                        </label>
                    </div>
                </div>
            </div>
        );
    };
    const handleSave = async () => {
        try {
            const x = validate('enterprise', documentData);
            if (!isValid('enterprise', x)) {
                Object.values(x).forEach((error) => {
                    if (error) {
                        notifyError(error);
                    }
                });
            } else {
                const res = await documentApi.create({
                    ...documentData,
                    name: documentData.name,
                    ecommerce_id: documentData.ecommerce_id,
                    image_url: documentData.image_url.map((item) => {
                        if (item.url) return item.url;
                        return item;
                    }),
                });
                if (res.status === 400) {
                    notifyError(t('text.add_material_failed'));
                } else {
                    notifySuccess(t('text.add_material_success'));
                    setTimeout(() => {
                        navigate('/list-documents');
                    }, 3000);
                }
            }
        } catch (error) {
            notifyError(error);
        }
    };
    return (
        <div className={cx('wrapper')}>
            <ToastPopup />
            <div className={cx('inner')}>
                <div className={cx('header-main')}>
                    <div className={cx('header-title')}>
                        <span>{t('text.material')}</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>{t('text.add_material')}</span>
                    </div>
                </div>
                <div className={cx('main-content')}>
                    <Form name="form-add-material">
                        <div className={cx('content')}>
                            <div className={cx('content-detail-1')}>
                                <label>{t('text.material_name')}</label>
                                <Form.Item name="name" style={{ width: '100%' }}>
                                    <Input
                                        style={{ width: '100%' }}
                                        className={cx('input-7')}
                                        placeholder="Input name"
                                        value={documentData.name}
                                        onChange={(e) =>
                                            setDocumentData({
                                                ...documentData,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>
                            </div>
                            <div className={cx('content-detail-1')}>
                                <label>{t('text.material_type')}</label>
                            </div>
                            <div className={cx('category-material')}>
                                <Select
                                    placeholder="Danh mục tài liệu"
                                    style={{
                                        width: '200px',
                                    }}
                                    onChange={(value) => {
                                        setDocumentData({
                                            ...documentData,
                                            category_id: value,
                                        });
                                    }}
                                >
                                    <Option value={531}>Đào tạo về kinh doanh</Option>
                                    <Option value={532}>Các chương trình xúc tiến</Option>
                                    <Option value={534}>Các báo cáo phân tích</Option>
                                </Select>
                            </div>
                            <div className={cx('content-detail-2')}>
                                <FontAwesomeIcon icon={faAdd} />
                                <input
                                    type="file"
                                    multiple
                                    style={{
                                        display: 'none',
                                    }}
                                    id="file-upload"
                                    onChange={handleFilesChange}
                                />
                                <label
                                    htmlFor="file-upload"
                                    className={cx('file-upload')}
                                    style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {documentData.image_url === null || documentData.image_url?.length === 0
                                        ? isEmpty
                                        : isNotEmpty(documentData.image_url)}
                                    {/* Thêm tài liệu */}
                                </label>
                            </div>
                            <button className={cx('btn-add')} onClick={handleSave}>
                                {t('text.add_material')}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CreateDocuments;
