import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews, getLikeNews } from "~/store/News/newsSlice";
import { useTranslation } from "react-i18next";
import "./SeeMoreNews.scss";
import { useNavigate } from "react-router-dom";

const SeeMoreNews = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllNews({ index: 1, size: 100 }));
    dispatch(getLikeNews(5));
  }, [dispatch]);
  const { popularEvent, story, investment } = useSelector(
    (state) => state.newsReducer
  );
  // console.log(data);
  const listLikeNews = useSelector((state) => state.newsReducer.listLikeNews);
  const navigate = useNavigate();

  const dataStorage = sessionStorage.getItem("dataStorage");

  return (
    <>
      <div className="container">
        <div className="see-more-news-container">
          <div className="title-category">
            <h1>
              {dataStorage === "popularEvent" && t("text.Highlight_events")}
              {dataStorage === "story" && t("text.Success_stories")}
              {dataStorage === "investment" && t("text.Connect_to_invest")}
            </h1>
          </div>
          <div className="item">
            {dataStorage === "popularEvent" &&
              popularEvent.map((item, index) => (
                <div key={index} className="see-more-news-item">
                  <img
                    src={
                      item.image_url === undefined
                        ? ""
                        : `${process.env.REACT_APP_API_URL}${item.image_url[0].url}`
                    }
                    alt="Error"
                  />
                  <div style={{ padding: "16px" }}>
                    <p
                      className="name-news"
                      onClick={() => {
                        navigate(`/news/${item?.id}`);
                      }}
                    >
                      {item.name}
                    </p>
                    <span className="desc">{item.des}</span>
                    <span className="time">
                      {" "}
                      {item?.created_date?.substring(0, 10)}
                    </span>
                  </div>
                </div>
              ))}
            {dataStorage === "story" &&
              story.map((item, index) => (
                <div key={index} className="see-more-news-item">
                  <img
                    src={
                      item.image_url === undefined
                        ? ""
                        : `${process.env.REACT_APP_API_URL}${item.image_url[0].url}`
                    }
                    alt="Error"
                  />
                  <div style={{ padding: "16px" }}>
                    <p
                      className="name-news"
                      onClick={() => {
                        navigate(`/news/${item?.id}`);
                      }}
                    >
                      {item.name}
                    </p>
                    <span className="desc">{item.des}</span>
                  </div>
                </div>
              ))}
            {dataStorage === "investment" &&
              investment.map((item, index) => (
                <div key={index} className="see-more-news-item">
                  <img
                    src={
                      item.image_url === undefined
                        ? ""
                        : `${process.env.REACT_APP_API_URL}${item.image_url[0].url}`
                    }
                    alt="Error"
                  />
                  <div style={{ padding: "16px" }}>
                    <p
                      className="name-news"
                      onClick={() => {
                        navigate(`/news/${item?.id}`);
                      }}
                    >
                      {item.name}
                    </p>
                    <span className="desc">{item.des}</span>
                  </div>
                </div>
              ))}
          </div>

          <div></div>
        </div>
        <div className="top-news-right">
          <div className="advertise">
            <img
              src="https://images.adsttc.com/media/images/5b08/b87c/f197/ccb5/4900/00bd/medium_jpg/The_S_02_filter_edit2_06.jpg?1527298139"
              alt=""
            />
          </div>
          <div className="most-watch">
            <h2>{t("text.Most_popular_post")}</h2>
            <div className="list-news">
              {listLikeNews.map((itemNews) => {
                return (
                  <div className="news-item" key={itemNews.id}>
                    <span
                      className="news-title"
                      onClick={() => {
                        navigate(`/news/${itemNews?.id}`);
                      }}
                    >
                      {itemNews?.name}
                    </span>
                    <div className="bottom">
                      <span className="category">
                        {itemNews?.news_categories === undefined
                          ? ""
                          : itemNews?.news_categories.name}
                      </span>
                      <span className="time">
                        {itemNews.created_date.substring(0, 10)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeeMoreNews;
