import { Menu } from "antd";
import { useState } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ModalRegister from "~/components/Modal/ModalRegister";
import logo from "~/assets/images/home/header/logo.png";
import styles from "./HeaderUser.module.scss";
import CurrentUser from "../CurrentUser";
import Language from "../Language";

const cx = classNames.bind(styles);

function HeaderUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentModal, setCurrentModal] = useState(false);

  const [current, setCurrent] = useState("home");

  const user =
    localStorage.getItem("user") === null
      ? null
      : JSON.parse(localStorage.getItem("user"));

  const handleModalLogin = () => {
    setCurrentModal(true);
  };
  const handleClose = (e) => {
    setCurrentModal(false);
  };
  const menuHeader = [
    {
      name: "home",
      title: t("homecustomer.home"),
      link: "/",
    },
    {
      name: "introduce",
      title: t("homecustomer.introduce"),
      link: "/introduce",
    },
    {
      name: "events",
      title: t("homecustomer.events"),
      link: "/events",
    },
    {
      name: "stores",
      title: t("homecustomer.stores"),
      link: "/company",
    },
    {
      name: "activities",
      title: t("homecustomer.activity"),
      link: "/activities",
    },
    {
      name: "news",
      title: t("homecustomer.news"),
      link: "/news",
    },
    {
      name: "document",
      title: t("homecustomer.document"),
      link: "/document",
    },
  ];
  console.log(current)
  return (
    <div className={cx("header-wrapper")}>
      <ModalRegister visible={currentModal} onHide={handleClose} />
      <header className={cx("header")}>
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>

        {user !== null && user.type === 2 ? (
          <Link className={cx("to-business")} to={"/user/register-business"}>
            {t("homecustomer.beenterprise")}
          </Link>
        ) : (
          <></>
        )}

        <div className={cx("admin-profile")}>
          <nav className={cx("nav-group")}>
            <Menu mode="horizontal" selectedKeys={[current]} >
              {menuHeader.map((route, index) => (
                <Menu.Item key={route.name} onClick={()=>setCurrent(route.name)}>
                  <Link key={index} to={route.link}>
                    {route.title}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </nav>
          {user !== null ? (
            <>
              <div className={cx("language")}>
                <Language />
              </div>
              <CurrentUser />
            </>
          ) : (
            <>
              <div className={cx("action-btn")}>
                <button
                  className={cx("btn-login")}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  {t("homecustomer.login")}
                </button>
                <button
                  className={cx("btn-register")}
                  onClick={() => handleModalLogin()}
                >
                  {t("homecustomer.register")}
                </button>
              </div>
              <div className={cx("language")}>
                <Language />
              </div>
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default HeaderUser;
