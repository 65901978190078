import axios from 'axios';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, message, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getFieldById } from '~/store/Store/storeSlice';
import storeApi from '~/api/StoreApi/storeApi';
import emptyIcon from '~/assets/images/Products/empty.svg';
import uploadIcon from '~/assets/images/Products/upload.svg';
import './CreateField.scss';

const CreateField = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const { id } = useParams();
    let formData = new FormData();
    const [fieldImage, setFieldImage] = useState([]);
    const [formEdit] = Form.useForm();
    const [fieldData, setFieldData] = useState({
        name: '',
        des: '',
        image_url: [],
    });

    const { fieldById, loading } = useSelector((state) => state.storesReducer);

    useEffect(() => {
        if (isEdit) {
            dispatch(getFieldById(id));
        }
    }, [dispatch, id, isEdit]);

    useEffect(() => {
        if (isEdit) {
            const dataEdit = {
                name: fieldById.name,
                des: fieldById.des,
            };
            setFieldData({
                ...fieldData,
                image_url: fieldById.image_url,
            });
            formEdit.setFieldsValue(dataEdit);
        }
    }, [fieldById]);

    const handleChangeField = (e) => {
        setFieldData((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleFilesChange = async (e) => {
        setFiles(e.target.files);
        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setFiles([]);
            setFieldData({
                ...fieldData,
                image_url: fieldData.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
        } catch (error) {
            console.log(error);
        }
    };

    const removeImage = (position) => {
        setFieldData({
            ...fieldData,
            image_url: fieldData.image_url.filter((_, index) => position !== index),
        });
    };

    const isEmpty = (
        <div className="is-empty">
            <h3>Hiện tại chưa có ảnh</h3>
            <img src={emptyIcon} alt="empty" />
            <input
                type="file"
                multiple
                accept="img/*"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            />
            <label style={{ cursor: 'pointer' }} htmlFor="file-upload">
                Thêm ảnh
            </label>
        </div>
    );

    const isNotEmpty = (arr) => {
        return (
            <div className="image-container">
                <div className="big-image">
                    <div className="delete-image" onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={arr === undefined ? '' : `${process.env.REACT_APP_API_URL}/${arr[0].url}`} alt="big" />
                </div>
                <div className="small-image-container">
                    {arr === undefined ? (
                        <></>
                    ) : (
                        arr.map((item, index) => {
                            if (index !== 0) {
                                return (
                                    <div
                                        className="small-image"
                                        key={item.url}
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                        }}
                                    >
                                        <div className="delete-image" onClick={() => removeImage(index)}>
                                            X
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })
                    )}
                    <div className="small-image upload">
                        <input
                            className="item__input"
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>Thêm ảnh</h4>
                            <img src={uploadIcon} alt="upload" />
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const handleSubmit = async (value) => {
        if (!isEdit) {
            const dataAdd = {
                name: value.name,
                des: value.des,
                image_url: fieldData.image_url.map((item) => item.url),
            };

            const result = await storeApi.createField(dataAdd);
            if (result.message === 'error') {
                message.error('Tạo lĩnh vực không thành công');
            } else {
                message.success('Tạo lĩnh vực thành công');
            }
        } else {
            const dataEdit = {
                name: value.name,
                des: value.des,
                image_url: fieldData.image_url.map((item) => item.url),
            };

            const res = await storeApi.editField({ id: id, body: dataEdit });

            if (res.status === 200) {
                message.success('Chỉnh sửa thành công');
            } else {
                message.error('Chỉnh sửa không thành công');
            }
        }
    };

    return (
        <div className="create-field" id="field-wrapper">
            <Spin spinning={loading}>
                <div className="inner">
                    <div className="header-main">
                        <div className="header-title">
                            <span>{t('text.company')}</span>
                            <FontAwesomeIcon icon={faAngleRight} />
                            <span>{t('text.company_list')}</span>
                        </div>
                    </div>
                    <div className="content-main">
                        <div className="field-group">
                            <Form onFinish={handleSubmit} form={formEdit}>
                                <div className="form-group">
                                    <label>{t('text.Store_name')}</label>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tên gian hàng không được để trống',
                                            },
                                        ]}
                                    >
                                        <Input onChange={handleChangeField} placeholder="Nhập tên gian hàng" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <label>{t('text.Store_description')}</label>
                                    <Form.Item
                                        name="des"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tên doanh nghiệp không được để trống',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            onChange={handleChangeField}
                                            placeholder="Nhập mô tả gian hàng"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <label>{t('text.picture')}</label>
                                    <Form.Item
                                        name="image_url"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ảnh không được để trống',
                                            },
                                        ]}
                                    >
                                        <div className="product-image__content">
                                            {fieldData.image_url === null || fieldData.image_url?.length === 0
                                                ? isEmpty
                                                : isNotEmpty(fieldData.image_url)}
                                        </div>
                                    </Form.Item>
                                </div>

                                <div className="action-submit">
                                    <button type="submit">{isEdit ? 'Lưu thay đổi' : t('text.create_stores')}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default CreateField;
