import React from 'react'
import Document from '../..'

const AlbumDetails = ({listData, id}) => {
  // console.log(id);
  return (
    <>
        <div className='container-image'>
            {/* <h2>Sự kiện hợp tác SmileTech và ConnectUp</h2> */}
            <div className='image-details'>
               <img src={listData.image}/>
            </div>
        </div>
    </>

  )
}

export default AlbumDetails