const images = {
  logo: require("~/assets/images/home/header/logo.svg").default,
  logofter: require("~/assets/images/home/header/logofter.svg").default,
  logoTechfest: require("~/assets/images/home/header/logo-techfest.svg")
    .default,
  userNameIcon: require("~/assets/images/AdminLogin/user.svg").default,
  passwordIcon: require("~/assets/images/AdminLogin/password.svg").default,
  avatar: require("~/assets/images/AdminLogin/avatar.svg").default,
  userIcon: require("~/assets/images/AdminSidebar/userIcon.svg").default,
  businessIcon: require("~/assets/images/AdminSidebar/businessIcon.svg")
    .default,
  courseIcon: require("~/assets/images/AdminSidebar/courseIcon.svg").default,
  groupIcon: require("~/assets/images/AdminSidebar/groupIcon.svg").default,
  postsIcon: require("~/assets/images/AdminSidebar/postsIcon.svg").default,
  phoneIcon: require("~/assets/images/ListBusiness/phoneIcon.svg").default,

  location: require("~/assets/images/ListCourses/location.svg").default,
  people: require("~/assets/images/ListCourses/people.svg").default,
  timer: require("~/assets/images/ListCourses/timer.svg").default,
  user: require("~/assets/images/ListCourses/user.svg").default,
  card: require("~/assets/images/ListCourses/card.svg").default,
  onePay: require("~/assets/images/ListCourses/onePay.svg").default,

  vi: require("~/assets/images/home/header/vi.svg").default,
  en: require("~/assets/images/home/header/en.svg").default,
  vector: require("~/assets/images/home/header/vector.svg").default,

  guilding1: require("~/assets/images/introduce/guilding1.svg").default,
  guilding2: require("~/assets/images/introduce/guilding2.svg").default,
  advisor1: require("~/assets/images/introduce/advisor1.svg").default,
  advisor2: require("~/assets/images/introduce/advisor2.svg").default,
  advisor3: require("~/assets/images/introduce/advisor3.svg").default,
  advisor4: require("~/assets/images/introduce/advisor4.svg").default,
  tatic: require("~/assets/images/introduce/tatic.svg").default,
  map: require("~/assets/images/introduce/map.svg").default,
  date: require("~/assets/images/introduce/date.svg").default,

  userItem: require("~/assets/images/ListMenuItems/userItem.svg").default,
  orderItem: require("~/assets/images/ListMenuItems/orderItem.svg").default,
  //login

  //stores/creator/list stores
  creator1: require("~/assets/images/Creator/creator1.svg").default,
  logostores1: require("~/assets/images/liststores/logostore1.svg").default,
  imagestore1: require("~/assets/images/liststores/imagestore1.svg").default,
  imagestore2: require("~/assets/images/liststores/imagestore2.svg").default,
  imagestore3: require("~/assets/images/liststores/imagestore3.svg").default,
  //liststores/icon button
  calling: require("~/assets/images/liststores/call-calling.svg").default,
  calendar: require("~/assets/images/liststores/calendar.svg").default,
  message: require("~/assets/images/liststores/message-text.svg").default,
  //liststores/product
  smsIcon: require("~/assets/images/liststores/sms.svg").default,
  // socical
  twitter: require("~/assets/images/Social/twitter.svg").default,
  linkien: require("~/assets/images/Social/linkien.svg").default,
  fb: require("~/assets/images/Social/fb.svg").default,
  whatsapp: require("~/assets/images/Social/whatsapp.svg").default,
  mess: require("~/assets/images/Social/mess.svg").default,
  // documents
  documenticonimage: require("~/assets/images/doocuments/gallery.svg").default,
  documenticonfolder: require("~/assets/images/doocuments/folder-open.svg")
    .default,
  documenticonvideo: require("~/assets/images/doocuments/video-play.svg")
    .default,
  documenticontime: require("~/assets/images/doocuments/clock.svg").default,
  documenticoneye: require("~/assets/images/doocuments/eye.svg").default,
  documenticondownload:
    require("~/assets/images/doocuments/directbox-receive.svg").default,
  documenticondownvector: require("~/assets/images/doocuments/Vector.svg")
    .default,
  // documenticonTL: require("~/assets/images/doocuments/document-icon.svg")
  //   .default,

  //business
  businessFilter: require("~/assets/images/ListBusiness/Group.svg").default,
  businessFilter2:
    require("~/assets/images/ListBusiness/textalign-justifycenter.svg").default,

  postImage: require("~/assets/images/PostDetails/postImage.svg").default,

  avatarUser: require("~/assets/images/User/avataruser.svg").default,
  atmImage: require("~/assets/images/CardProduct/ATM.svg").default,
  CODImage: require("~/assets/images/CardProduct/COD.svg").default,
  visaImage: require("~/assets/images/CardProduct/Visa.svg").default,
  masterCardImage: require("~/assets/images/CardProduct/MasterCard.svg")
    .default,
  locationIcon: require("~/assets/images/Products/location.svg").default,
  rateIcon: require("~/assets/images/Products/rate.svg").default,
  shopIcon: require("~/assets/images/Products/shop.svg").default,
  adsIcon: require("~/assets/images/Products/ads.svg").default,
  programIcon: require("~/assets/images/Products/myProgram.svg").default,
  strongboxIcon: require("~/assets/images/Products/strongbox.svg").default,
  productsIcon: require("~/assets/images/Products/products.svg").default,
};
export default images;
