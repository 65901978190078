import axios from "axios";
import JoditEditor from "jodit-react";
import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import {
  editProject,
  getAllCountry,
  getProjectById,
} from "~/store/Projects/projectsSlice";
import { getAllCities } from "~/store/CustomerProfile/customerSlice";
import { getMyStore } from "~/store/Store/storeSlice";
import uploadIcon from "~/assets/images/Products/upload.svg";
import emptyIcon from "~/assets/images/Products/empty.svg";
import { projectsApi } from "~/api/Projects/projectsApi";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "./CreateProject.scss";

const dateFormat = "YYYY/MM/DD";
const { Option } = Select;

const CreateProject = ({ isEdit = false }) => {
  const { id } = useParams();
  const navgate = useNavigate();
  const dispatch = useDispatch();
  const { listCountry, projectById } = useSelector(
    (state) => state.projectReducer
  );
  const { allCities } = useSelector((state) => state.customerReducer);
  const [fileList, setFileList] = useState([]);
  let [formProject] = Form.useForm();

  const [productData, setProductData] = useState({
    image_url: [],
  });

  let documentData = new FormData();

  const propsUpload = {
    name: "files",
    onRemove: (file) => {
      setFileList((prevState) => {
        const index = prevState.indexOf(file);
        const newFileList = prevState.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList((prevState) => [...prevState, file]);
      return false;
    },
    fileList,
    maxCount: 1,
  };
  const { storeById } = useSelector((state) => state.storesReducer);
  console.log(storeById?.id);
  useEffect(() => {
    dispatch(getAllCountry());
    dispatch(getAllCities());
    dispatch(getMyStore());
    if (isEdit) {
      dispatch(getProjectById(id));
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataSubmit = {
        name: projectById.name,
        amount_call: parseInt(projectById.amount_call),
        amount_called: parseInt(projectById.amount_called),
        city_id: projectById?.city_id?.id,
        country_id: projectById?.country_id?.id,
        status: projectById.status,
        website: projectById.website,
        des: projectById.des,
        established_date: dayjs(
          `${projectById?.established_date?.toString()}`,
          "YYYY"
        ),
      };

      setProductData({ image_url: projectById.image_url });
      formProject.setFieldsValue(dataSubmit);
    }
  }, [projectById]);

  let formData = new FormData();

  const handleFilesChange = async (e) => {
    for (const key of Object.keys(e.target.files)) {
      formData.append("files", e.target.files[key]);
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload/upload-array`,
        formData
      );
      setProductData({
        ...productData,
        image_url: productData.image_url.concat(
          res.data.url.map((image) => {
            return {
              url: image,
            };
          })
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const removeImage = (position) => {
    setProductData({
      ...productData,
      image_url: productData.image_url.filter((_, index) => position !== index),
    });
  };

  const isEmpty = (
    <div className="is-empty">
      <h3>Hiện tại chưa có ảnh</h3>
      <img src={emptyIcon} alt="empty" />
      <input
        type="file"
        multiple
        accept="img/*"
        id="file-upload"
        style={{ display: "none" }}
        onChange={handleFilesChange}
      />
      <label style={{ cursor: "pointer" }} htmlFor="file-upload">
        Thêm ảnh
      </label>
    </div>
  );

  const isNotEmpty = (arr) => {
    return (
      <div className="image-container">
        <div className="big-image">
          <div className="delete-image" onClick={() => removeImage(0)}>
            X
          </div>
          <img
            src={
              arr === undefined
                ? ""
                : `${process.env.REACT_APP_API_URL}/${arr[0].url}`
            }
            alt="big"
          />
        </div>
        <div className="small-image-container">
          {arr === undefined ? (
            <></>
          ) : (
            arr.map((item, index) => {
              if (index !== 0) {
                return (
                  <div
                    className="small-image"
                    key={item.url}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                    }}
                  >
                    <div
                      className="delete-image"
                      onClick={() => removeImage(index)}
                    >
                      X
                    </div>
                  </div>
                );
              }
              return null;
            })
          )}
          <div className="small-image upload">
            <input
              className="item__input"
              type="file"
              id="file-upload"
              multiple
              accept="image/*"
              onChange={handleFilesChange}
            />
            <label htmlFor="file-upload">
              <h4>Thêm ảnh</h4>
              <img src={uploadIcon} alt="upload" />
            </label>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = async (value) => {
    if (isEdit) {
      const dataSubmit = {
        name: value.name,
        amount_call: parseInt(value.amount_call),
        amount_called: parseInt(value.amount_called),
        city_id: value.city_id,
        country_id: value.country_id,
        established_date: parseInt(value["established_date"].format("YYYY")),
        status: value.status,
        website: value.website,
        des: value.des,
        document_url: projectById?.document_url?.map((item) => item?.url),
        image_url: productData?.image_url.map((item) => item.url),
        store_id: storeById?.id,
        intro: value?.intro,
      };

      //   console.log("datasubmitedt", dataSubmit);
      dispatch(editProject({ id: id, body: dataSubmit })).then((res) => {
        console.log("res", res);
        if (res.payload.status === 200) {
          message.success("Chỉnh sửa dự án thành công");
          formProject.resetFields();
          navgate("/businessadmin/project-manage");
        } else {
          message.error("Có lỗi xảy ra");
        }
      });
      //   const resEdit = await projectsApi.edit({ id: id, body: dataSubmit });
      //   console.log("res", resEdit);
      //   if (resEdit.status === 200) {
      //     message.success("Chỉnh sửa dự án thành công");
      //     formProject.resetFields();
      //   } else {
      //     message.error("Có lỗi xảy ra");
      //   }
      //     }
      // } catch (error) {
      //     console.log(error);
      // }
    } else {
      for (let key of fileList) {
        formData.append("files", key);
      }

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload/upload-array`,
          formData
        );
        if (res.status === 200) {
          const dataSubmit = {
            name: value.name,
            amount_call: parseInt(value.amount_call),
            amount_called: parseInt(value.amount_called),
            city_id: value.city_id,
            country_id: value.country_id,
            established_date: parseInt(
              value["established_date"].format("YYYY")
            ),
            status: value.status,
            website: value.website,
            des: value.des,
            document_url: res.data.url,
            image_url: productData.image_url.map((item) => item.url),
            store_id: storeById?.id,
            intro: value?.intro,
          };

          const resCreate = await projectsApi.create(dataSubmit);
          if (resCreate.status === 200) {
            message.success("Tạo dự án thành công");
            formProject.resetFields();
            navgate("/businessadmin/project-manage");
          } else {
            message.error("Có lỗi xảy ra");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // console.log("abc", projectById?.established_date);

  const abc = "2022";

  return (
    <div className="create-project-wrapper">
      <Form layout="vertical" onFinish={onSubmit} form={formProject}>
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <Form.Item
              name="name"
              label="Tên dự án"
              rules={[
                {
                  required: true,
                  message: "Tên dự án không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập tên dự án" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="established_date"
              label="Năm thành lập"
              rules={[
                {
                  required: true,
                  message: "Năm thành lập không được để trống",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} picker="year" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item
              name="country_id"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country không được để trống",
                },
              ]}
            >
              <Select
                placeholder="Chọn country"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listCountry.map((country) => {
                  return (
                    <Option value={country.id} key={country.id}>
                      {country?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="city_id"
              label="Tỉnh/Thành phố"
              rules={[
                {
                  required: true,
                  message: "Tỉnh/thành phố không được để trống",
                },
              ]}
            >
              <Select
                placeholder="Chọn tỉnh/thành phố"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allCities.map((city) => {
                  return (
                    <Option value={city.id} key={city.id}>
                      {city.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="website"
              label="Website"
              rules={[
                {
                  required: true,
                  message: "Website phố không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item
              name="amount_call"
              label="Nhập số tiền muốn gọi"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số tiền muốn gọi",
                },
              ]}
            >
              <Input placeholder="Nhập số tiền cần gọi" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="amount_called"
              label="Số tiền đã kêu gọi"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số tiền đã gọi",
                },
              ]}
            >
              <Input placeholder="Nhập số tiền cần gọi" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="status"
              label="Giai đoạn tài trợ"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn giai đoạn tài trợ",
                },
              ]}
            >
              <Select placeholder="Giai đoạn tài trợ">
                <Option value={1}>Pre-seed</Option>
                <Option value={2}>Seed</Option>
                <Option value={3}>Series A</Option>
                <Option value={4}>Series B</Option>
                <Option value={5}>Series C</Option>
                <Option value={6}>Series D</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="intro"
          label="Mô tả"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mô tả dịch vụ",
            },
          ]}
        >
          <Input placeholder="Nhập số tiền cần gọi" />
        </Form.Item>
        <Form.Item
          name="des"
          label="Mô tả chi tiết"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mô tả dịch vụ",
            },
          ]}
        >
          <JoditEditor />
        </Form.Item>
        <Form.Item name="document" label="Tài liệu dự án">
          <Upload {...propsUpload}>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                padding: "1px 8px",
              }}
              icon={<UploadOutlined />}
            >
              Chọn tài liệu
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item name="image_group" label="Hình ảnh">
          <div className="image-group">
            {productData.image_url === null ||
            productData.image_url?.length === 0
              ? isEmpty
              : isNotEmpty(productData.image_url)}
          </div>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Thêm
        </Button>
      </Form>
    </div>
  );
};

export default CreateProject;
