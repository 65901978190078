import React from "react";
import { useNavigate } from "react-router-dom";
import "./CardStory.scss";

import { dateCommon } from "~/utils/dateCommon";

const CardStory = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className="card-story-item">
      <img
        src={`${process.env.REACT_APP_API_URL}${item.image_url[0]?.url}`}
        alt=""
      />
      <div className="card-story-left">
        <h3
          className="title-story"
          onClick={() => {
            navigate(`/news/${item?.id}`);
          }}
        >
          {item?.name}
        </h3>
        <span className="des">{item?.des}</span>
        <div className="card-bottom">
          <span className="time"> {item?.created_date?.substring(0, 10)}</span>
        </div>
      </div>
    </div>
  );
};

export default CardStory;
