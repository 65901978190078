import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDocumentAllPaging } from "~/store/Documents/documentsSlice";
import documentIconAcyive from "../../../assets/images/doocuments/document-icon-active.svg";
import documentIcon from "../../../assets/images/doocuments/document-icon.svg";
import ImageIconActive from "../../../assets/images/doocuments/image-icon-active.svg";
import ImageIcon from "../../../assets/images/doocuments/image-icon.svg";
import ListAlbum from "./Components/ListAlbum";
import ListDocuments from "./Components/ListDocument";
import "./Document.scss";

// const cx = classNames.bind(styles);
function Document() {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {}, [dispatch]);
  const navigate = useNavigate();
  // const { documentsAllPaging } = useSelector((state) => state.documentsReducer);
  // console.log(documentsAllPaging.data);
  useEffect(() => {
    dispatch(
      getDocumentAllPaging({
        index: 1,
        size: 10,
        type: 3,
      })
    );
  }, [dispatch]);

  const [select, setSelect] = useState(1);

  function selectEventDocument() {
    setSelect(1);
  }

  function selectImage() {
    setSelect(2);
  }

  return (
    <div className="document">
      <div className="documents-container">
        <Button
          className="btn"
          onClick={selectEventDocument}
          // icon={<FolderTwoTone />}
        >
          {/* <span className="icon-doc icon-color-active"></span> */}
          <img
            src={select === 1 ? documentIconAcyive : documentIcon}
            className="icon-doc"
          />
          <span
            style={{
              color: `${select === 1 ? "#019DB4" : "black"}`,
            }}
          >
            TÀI LIỆU SỰ KIỆN
          </span>
        </Button>
        <Button
          className="btn"
          onClick={selectImage}
          // icon={<FileImageTwoTone />}
        >
          <img
            src={select === 2 ? ImageIconActive : ImageIcon}
            className="icon-doc"
          />
          <span
            style={{
              color: `${select === 2 ? "#019DB4" : "black"}`,
            }}
          >
            ALBUM ẢNH
          </span>
        </Button>
      </div>
      <div className="event-document-list">
        {select === 1 && <ListDocuments />}
        {select === 2 && <ListAlbum idSelect={select} />}
      </div>
    </div>
  );

  // const MENU = [
  //   {
  //     id: 1,
  //     title: t("text.Event_Material"),
  //     image: <img src={images.documenticonfolder} alt="" />,
  //   },
  //   {
  //     id: 2,
  //     title: t("text.Gallery"),
  //     image: <img src={images.documenticonimage} alt="" />,
  //   }
  // ];
  // const [currentId, setCurrentId] = useState(1);

  // console.log(currentId);

  // const handleSwitch = (e) => {
  //   console.log(e);
  //   setCurrentId(e);
  // };

  // const list = MENU.map((item, index) => {
  //   return (
  //     <div key={index}>
  //       <ButtonComponent
  //         onClick={() => handleSwitch(item.id)}
  //         leftIcon={item.image}
  //         // outline
  //         large
  //         className={
  //           currentId === item.id ? `${cx("currentId")}` : `${cx("currentId1")}`
  //         }
  //       >
  //         {item.title}
  //       </ButtonComponent>
  //     </div>
  //   );
  // });

  // return (
  //   <div className={cx("wrapper")}>
  //     <div className={cx("inner")}>
  //       <div className={cx("content-btn")}>
  //         <div className={cx("btn")}>{list}</div>
  //         <div className={cx("detail")}>
  //           {currentId === 2 ? (
  //             <div className={cx("deltai-images")}>
  //               <ListAlbum />
  //             </div>
  //           ) : (
  //             <></>
  //           )}
  //           {currentId === 1 ? <ListDocument /> : <></>}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Document;
