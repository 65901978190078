import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrderApi from '~/api/Order/orderApi';

export const getOrderByStatus = createAsyncThunk('news/getOrderByStatus', async (data) => {
    const listData = await OrderApi.getOrderByStatus(data);
    return listData;
});

const orderSlice = createSlice({
    name: 'news',
    initialState: {
        loading: false,
        listOrderByStatus: [],
        listPurchasedProduct: [],
    },
    reducers: {},
    extraReducers: {
        [getOrderByStatus.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrderByStatus.rejected]: (state, action) => {
            state.loading = false;
        },
        [getOrderByStatus.fulfilled]: (state, action) => {
            state.loading = false;
            state.listOrderByStatus = action.payload;
            const tpArray = [];
            const tpData = action.payload?.data?.map((item) => {
                item?.details?.map((itemProd) => {
                    tpArray.push(itemProd);
                });
            });
            state.listPurchasedProduct = tpArray;
        },
    },
});

const { reducer: orderReducer } = orderSlice;
export default orderReducer;
