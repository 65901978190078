import React from "react";
import classNames from "classnames/bind";
import styles from "./CardProduct.module.scss";
import { Rate } from "antd";
import formatCash from "~/utils/formatCash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(styles);

const CardProduct = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleShowDetailsProduct = (item) => {
    navigate(`/stores/viewstore/detail-product/${item.id}`);
    window.scrollTo({ top: 570, left: 0, behavior: "smooth" });
  };

  return (
    <div className={cx("card-wrapper")}>
      <div
        className={cx("card-image")}
        onClick={() => handleShowDetailsProduct(item)}
      >
        <img
          src={`${process.env.REACT_APP_API_URL}${item?.image_url[0].url}`}
          alt="Avatar"
        />
      </div>
      <div className={cx("card-details")}>
        <span
          className={cx("card-details-title")}
          onClick={() => handleShowDetailsProduct(item)}
        >
          {item?.name}
        </span>
        <div className={cx("rating")}>
          <Rate disabled defaultValue={4} />
        </div>
        <div style={{ paddingTop: "8px" }} className={cx("price")}>
          <span className={cx("price-title")}>
            {" "}
            {t("homecustomer.price")}:{" "}
          </span>
          {item?.price === 0 ? (
            <span className={cx("price-number")}>Liên hệ</span>
          ) : (
            <span className={cx("price-number")}>
              {formatCash(item?.price.toString())} đ
            </span>
          )}
          {/* <span className={cx("price-number")}>
            {formatCash(item?.price.toString())} đ
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
