import { HeartOutlined } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import parse from "html-react-parser";
import { useState } from "react";
import formatCash from "~/utils/formatCash";
import hive from "../../../../../assets/images/logo/hive.jpg";
import "./CardTopProject.scss";
import RegisterInvestmentForm from "./RegisterInvestmentForm";
const CardTopProject = ({ projectById }) => {
  const [showRegForm, setShowRegForm] = useState(false);
  const [imageArr, setImageArr] = useState([
    {
      id: 1,
      url: "https://cdn.tgdd.vn/hoi-dap/845011/mang-xa-hoi-la-gi%20(4)-800x450.jpg",
      active: true,
    },
    {
      id: 2,
      url: "https://static6.depositphotos.com/1014550/624/i/450/depositphotos_6240474-stock-photo-test-word-on-keyboard.jpg",
      active: false,
    },
    {
      id: 3,
      url: "https://cdn.pixabay.com/photo/2014/06/03/19/38/board-361516__340.jpg",
      active: false,
    },
    {
      id: 4,
      url: "https://img.freepik.com/premium-vector/job-exam-test-vector-illustration_138676-243.jpg?w=360",
      active: false,
    },
  ]);
  const [idActive, setIdActive] = useState(1);
  const handleChangeImage = (item) => {
    setImageArr(
      projectById?.image_url?.map((imageItem) => {
        if (imageItem.id === item.id) {
          return {
            ...imageItem,
            setIdActive,
          };
        } else {
          return {
            ...imageItem,
          };
        }
      })
    );
  };

  return (
    <div className="card-top-project">
      <Row gutter={[16, 16]}>
        <Col
          span={12}
          style={{ paddingRight: "16px" }}
          className="card-top-left-wrap"
        >
          <div className="image-group">
            <div className="big-image">
              {projectById?.image_url?.map((itemImage) => {
                if (itemImage.id) {
                  return (
                    <img
                      src={`${process.env.REACT_APP_API_URL}${itemImage.url}`}
                      alt=""
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
            <div className="small-image">
              {projectById?.image_url?.map((itemImage) => {
                return (
                  <img
                    className={itemImage.id && "small-active"}
                    src={`${process.env.REACT_APP_API_URL}${itemImage.url}`}
                    alt=""
                    onClick={() => {
                      handleChangeImage(itemImage);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </Col>
        <Col
          span={12}
          style={{ paddingLeft: "16px" }}
          className="card-top-right-wrap"
        >
          <div className="card-top-right">
            <h3>{projectById?.name}</h3>
            <div className="investment-call">
              <div className="calling">
                <label>Kêu gọi</label>
                <span style={{ color: "red" }}>
                  {" "}
                  {formatCash(projectById?.amount_call?.toString())}đ
                </span>
              </div>
              <div className="called">
                <label>Đã kêu gọi</label>
                <span>
                  {formatCash(projectById?.amount_called?.toString())}đ
                </span>
              </div>
            </div>
            <div className="company-group">
              {/* <div className="company-image">
                <img src={hive} alt="" />
              </div> */}
              <div className="cardTop-company-detail">
                <div style={{ fontSize: "18px" }}>
                  {projectById?.store?.name}
                </div>
                {/* <div className="company-detail-bottom">
                  <div className="project-num">2 dự án</div>
                  <span>|</span>
                  <div className="project-location">Hanoi</div>
                </div> */}
              </div>
            </div>

            <span className="project-des">{projectById?.intro}</span>
            <div className="investment-action">
              <div
                className="primary-button"
                onClick={() => {
                  setShowRegForm(true);
                }}
              >
                Liên hệ đầu tư
              </div>
              <div className="follow-btn">
                <HeartOutlined />
                Theo dõi
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        name="modal-voucher-store"
        centered
        title="Đăng ký đầu tư"
        footer={null}
        onCancel={() => setShowRegForm(false)}
        visible={showRegForm}
      >
        <RegisterInvestmentForm setShowRegForm={setShowRegForm} />
      </Modal>
    </div>
  );
};

export default CardTopProject;
