import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CardInvestment.scss";
import VN from "../../../../assets/images/vn.png";
const CardInvestment = ({ setShowModalReg }) => {
  return (
    <div className="card-investment-item">
      <div className="card-investment-content">
        <div className="left-content">
          <div className="title">VinaCapital</div>

          <span>
            Công ty Quản lý Quỹ VinaCapital (“VCFM”) là một trong những công ty
            đầu ngành trong lĩnh vực đầu tư tài chính ở Việt Nam. Được thành lập
            bởi Tập đoàn VinaCapital từ năm 2012 và hoạt động dưới tên Công ty
            Cổ phần Quản lý Quỹ VinaWealth cho đến trước tháng 07 năm 2017, VCFM
            được cấp giấy phép bởi Ủy Ban Chứng Khoán Nhà Nước để thành lập và
            quản lý các quỹ đầu tư, quản lý danh mục đầu tư và cung cấp dịch vụ
            tư vấn đầu tư.
          </span>
          <div className="right-content">
            <button className="circle" onClick={() => setShowModalReg(true)}>
              Hướng dẫn đầu tư <FontAwesomeIcon icon={faArrowRightLong} />
            </button>
          </div>
        </div>
        <div className="image-group">
          <img src={VN} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CardInvestment;
