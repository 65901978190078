import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import images from "~/assets/images/home/header";
import styles from "./Footer.module.scss";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../../assets/images/footer.png";
import GHN from "../../../../assets/images/GHN.png";
const cx = classNames.bind(styles);

function Footer() {
  const navigate = useNavigate();

  return (
    <div className={cx("wrapper")}>
      <div className={cx("inner")}>
        <div className={cx("col-group")}>
          <h5>CÔNG TY CỔ PHẦN SMILETECH CÔNG NGHỆ SỐ </h5>
          <span className={cx("row-group")}>
            <FontAwesomeIcon className={cx("phone-icon")} icon={faPhone} />{" "}
            Hotline: 0912623203
          </span>
          <span className={cx("row-group")}>
            <FontAwesomeIcon className={cx("mail-icon")} icon={faEnvelope} />{" "}
            Mail: contact@smiletech.vn
          </span>
          <span className={cx("row-group")}>
            <FontAwesomeIcon
              className={cx("mail-icons")}
              icon={faLocationDot}
            />{" "}
            Địa chỉ: Villa 3 - B1, Ngõ 40 Xuân La, Xuân La, Tây Hồ, Hà Nội
          </span>
          <span className={cx("row-group")}>
            <FontAwesomeIcon className={cx("mail-iconss")} icon={faPhoneAlt} />{" "}
            Số điện thoại: (+84) 24 3350 3168
          </span>
          <h5 style={{ marginTop: "16px" }}>CHĂM SÓC KHÁCH HÀNG</h5>
          <p
            onClick={() => {
              navigate("/support");
            }}
          >
            Hướng dẫn mua hàng
          </p>
          <p
            onClick={() => {
              navigate("/terms");
            }}
          >
            Chính sách và quy trình xử lý đổi trả
          </p>
          <p
            onClick={() => {
              navigate("/privacy");
            }}
          >
            Chính sách giải quyết khiếu nại
          </p>
        </div>
        <div className={cx("col-group")}>
          <h5>PHƯƠNG THỨC THANH TOÁN</h5>
          <span className={cx("row-group")}>
            <a href="https://www.onepay.vn/" target="_blank">
              <img className={cx("payment-icon")} src={img} alt="" />
            </a>
          </span>
          <h5 className={cx("delivery")}>ĐỐI TÁC THANH TOÁN VÀ VẬN CHUYỂN</h5>
          <span className={cx("row-groups")}>
            <a href="https://ghn.vn/" target="_blank">
              <img className={cx("payment-icon")} src={GHN} alt="" />
            </a>
          </span>
        </div>
        <div className={cx("col-group")}>
          <h5>KẾT NỐI VỚI CHÚNG TÔI</h5>
          <span className={cx("row-group")}>
            <img className={cx("social-icon")} src={images.fb} alt="" />
            <img className={cx("social-icon")} src={images.twitter} alt="" />
            <img className={cx("social-icon")} src={images.whatsapp} alt="" />
            <img className={cx("social-icon")} src={images.linkien} alt="" />
          </span>
          <h5 className={cx("delivery")}>TẢI ỨNG DỤNG MUA HÀNG</h5>
          <span className={cx("row-group")}>
            <img
              src="https://tradeline.vn/_next/image?url=%2Fimages%2Fpayment%2FqrTradeline.png&w=96&q=75"
              alt=""
            />
            <div className={cx("right-action")}>
              <img
                src="https://tradeline.vn/images/payment/appstore.svg"
                alt=""
              />
              <img
                src="https://tradeline.vn/images/payment/ggplay.svg"
                alt=""
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
