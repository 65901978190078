import React from 'react'
import {Button} from 'antd'
import { useNavigate } from 'react-router-dom'

const ListAlbumDocument = ({listData, nhan}) => {
    function SeeDetailsAlbum() {
        const detailAlbum = 'detailAlbum'
        nhan(detailAlbum)
    }
    return (
        <div className='item-album'>
            <img src={listData.image}/>
            <p>{listData.name}</p>
            <Button onClick={SeeDetailsAlbum}>Xem chi tiết</Button>
        </div>
  )
}

export default ListAlbumDocument