import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storeApi from "~/api/StoreApi/storeApi";

export const getAllField = createAsyncThunk("store/getAllField", async () => {
  const listData = await storeApi.getAllField();
  return listData;
});

export const getFieldAllPaging = createAsyncThunk(
  "store/getFieldAllPaging",
  async (data) => {
    const listData = await storeApi.getFieldAllPaging(data);
    return listData;
  }
);

export const getFieldById = createAsyncThunk(
  "store/getFieldById",
  async (data) => {
    const listData = await storeApi.getFieldById(data);
    return listData;
  }
);

export const getMyStore = createAsyncThunk("store/getMyStore", async () => {
  const listData = await storeApi.getMyStore();
  return listData;
});

export const getStoreById = createAsyncThunk(
  "store/getStoreById",
  async (data) => {
    const listData = await storeApi.getStoreById(data);
    return listData;
  }
);

export const deleteField = createAsyncThunk(
  "store/deleteField",
  async (data, thunkAPI) => {
    const listData = await storeApi.deleteField(data);
    thunkAPI.dispatch(getAllField());
    return listData;
  }
);

export const getStoreAllPaging = createAsyncThunk(
  "store/getStoreAllPaging",
  async (data, thunkAPI) => {
    const listData = await storeApi.getStoreAllPaging(data.page, data.data);
    return listData;
  }
);

export const getAllStoreByField = createAsyncThunk(
  "store/getAllStoreByField",
  async (data, thunkAPI) => {
    const listData = await storeApi.getAllStoreByField(data);
    return listData;
  }
);

export const getStoreByField = createAsyncThunk(
  "store/getStoreByField",
  async (data, thunkAPI) => {
    const listData = await storeApi.getStoreByField(data);
    return listData;
  }
);

const storesSlice = createSlice({
  name: "store",
  initialState: {
    loading: false,
    listField: [],
    storeById: {},
    fieldById: [],
    listStoreAllPaging: [],
    listFieldAllPaging: [],
    totalField: "",
    storeDetail: {},
  },
  reducers: {},
  extraReducers: {
    [getAllField.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllField.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllField.fulfilled]: (state, action) => {
      state.loading = false;
      state.listField = action.payload;
    },
    [getAllStoreByField.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllStoreByField.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllStoreByField.fulfilled]: (state, action) => {
      state.loading = false;
      state.listStoreAllPaging = action.payload;
    },
    [getFieldAllPaging.pending]: (state, action) => {
      state.loading = true;
    },
    [getFieldAllPaging.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFieldAllPaging.fulfilled]: (state, action) => {
      state.loading = false;
      state.listFieldAllPaging = action.payload;
      state.totalField = action.payload.total_item;
    },
    [getMyStore.pending]: (state, action) => {
      state.loading = true;
    },
    [getMyStore.rejected]: (state, action) => {
      state.loading = false;
    },
    [getMyStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeById = action.payload;
      // console.log(action.payload);
    },
    [getStoreAllPaging.pending]: (state, action) => {
      state.loading = true;
    },
    [getStoreAllPaging.rejected]: (state, action) => {
      state.loading = false;
    },
    [getStoreAllPaging.fulfilled]: (state, action) => {
      state.loading = false;
      state.listStoreAllPaging = action.payload;
    },
    [getStoreById.pending]: (state, action) => {
      state.loading = true;
    },
    [getStoreById.rejected]: (state, action) => {
      state.loading = false;
    },
    [getStoreById.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeDetail = action.payload;
    },
    [getFieldById.pending]: (state, action) => {
      state.loading = true;
    },
    [getFieldById.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFieldById.fulfilled]: (state, action) => {
      state.loading = false;
      state.fieldById = action.payload;
    },
  },
});

const { reducer: storesReducer } = storesSlice;
export default storesReducer;
