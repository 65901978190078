import classNames from "classnames/bind";
import { t } from "i18next";
import { useState } from "react";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";

import styles from "./DetailEvent.module.scss";
import images from "~/assets/images/home/header";
import RegCompetition from "~/pages/LandingPage/Activities/Participate/RegCompetition";

const cx = classNames.bind(styles);

function DetailEvent({
  item,
  currentId,
  checkImage,
  handleDetail,
  index,
  currentDetail,
  eventType,
}) {
  const [currentModal, setCurrentModal] = useState(false);
  const handleModal = () => {
    setCurrentModal(true);
  };
  const onHide = () => {
    setCurrentModal(false);
  };
  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  }
  let date = new Date();
  // const getButtonClassName = () => {
  //   return disabled ? "disabled-button" : "";
  // };
  return (
    <div className={cx("inner")}>
      <Modal
        visible={currentModal}
        onCancel={onHide}
        footer={null}
        wrapClassName="modal-cus modal-register-competition"
        title="Đăng ký tham gia"
        centered
      >
        <RegCompetition
          eventType={eventType}
          visible={setCurrentModal}
          idCompetition={item}
        />
      </Modal>
      <div key={index} className={cx("contents")}>
        <div className={cx("content-img")}>
          <img src={checkImage(item.image_url)} alt="" />
        </div>
        <div className={cx("content-workshop")}>
          <div
            className={cx("content-des")}
            onClick={() => handleDetail(item.id)}
          >
            {item.name}
          </div>
          <div className={cx("content-btn2")}>
            {Date.parse(item.start_time) > Date.parse(date) ? (
              <button className={cx("status-btn", "upcoming")}>
                Sắp diễn ra
              </button>
            ) : Date.parse(item.end_time) < Date.parse(date) ? (
              <button className={cx("status-btn", "end")}>Kết thúc</button>
            ) : Date.parse(item.start_time) < date &&
              Date.parse(item.end_time) > Date.parse(date) ? (
              <button className={cx("status-btn", "in-progress")}>
                Đang diễn ra
              </button>
            ) : (
              ""
            )}
          </div>
          <div className={cx("acb")}>
            <div className={cx("content-btn1")}>
              Địa chỉ : <span>{item?.address}</span>
            </div>
            <div className={cx("content-time-place")}>
              Thời gian :{" "}
              <span>
                {formatDateTime(item.start_time)} -
                {formatDateTime(item.end_time)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {currentDetail && item.id === currentId ? (
        <div className={cx("detail")}>
          <div className={cx("content1")}>
            <div className={cx("title")}>Giới thiệu</div>
            <div className={cx("des")}>{item.content}</div>
          </div>
          <div className={cx("content2")}>
            <div className={cx("title")}>Địa điểm tổ chức</div>
            <div className={cx("des")}>{item.address}</div>
          </div>
          <div className={cx("btn")}>
            <Button
              style={{
                fontSize: "16px",
                padding: "8px 24px",
                borderRadius: "30px",
                height: "auto",
              }}
              className={
                Date.parse(item.start_time) > Date.parse(date)
                  ? cx("reg-btn")
                  : ""
              }
              disabled={
                Date.parse(item.start_time) > Date.parse(date)
                  ? false
                  : Date.parse(item.end_time) < Date.parse(date)
                  ? true
                  : Date.parse(item.start_time) < date &&
                    Date.parse(item.end_time) > Date.parse(date)
                  ? true
                  : true
              }
              onClick={handleModal}
              leftIcon={<img src={images.phoneIcon} />}
            >
              Đăng ký tham gia
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DetailEvent;
