import React from 'react';
import CreateField from '../CreateField';

const EditField = () => {
    return (
        <div>
            <CreateField isEdit={true} />
        </div>
    );
};

export default EditField;
