import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select } from "antd";
import { useState, useEffect } from "react";

import { getAllCities } from "~/store/CustomerProfile/customerSlice";
import { getAllField, getStoreAllPaging } from "~/store/Store/storeSlice";
import "./Search.scss";
import { useParams } from "react-router-dom";
const Search = () => {
  const { id } = useParams();
  const { Option } = Select;
  const dispatch = useDispatch();

  const { allCities } = useSelector((state) => state.customerReducer);
  const { listField } = useSelector((state) => state.storesReducer);

  useEffect(() => {
    dispatch(getAllCities());
    dispatch(getAllField());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmitSearch = () => {
    dispatch(
      getStoreAllPaging({
        page: {
          index: 1,
          size: 100,
        },
        data: searchData,
      })
    );
  };
  const nameFile = listField?.find((item) => item.id == id);
  console.log("iddd", nameFile);
  const [searchData, setSearchData] = useState({
    field_id: id ? nameFile.id : "",
    city_id: "",
    name: "",
  });
  return (
    <div className="search-inner">
      <div className="filter-field" style={{ width: "20%" }}>
        <Select
          style={{
            width: "100%",
            borderRadius: "14px",
          }}
          name="field_id"
          className="select-group"
          placeholder={id ? nameFile?.name : "Chọn lĩnh vực"}
          // defaultValue="?"
          onChange={(value) => {
            setSearchData({
              ...searchData,
              field_id: value,
            });
          }}
        >
          <Option value="">Tất cả</Option>
          {listField.map((item, index) => {
            return (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className="filter-location" style={{ width: "20%" }}>
        <Select
          style={{
            width: "100%",
          }}
          name="city_id"
          className="select-group"
          placeholder="Chọn khu vực"
          onChange={(value) => {
            setSearchData({
              ...searchData,
              city_id: value,
            });
          }}
        >
          <Option value="">Tất cả</Option>
          {allCities.map((item, index) => {
            return (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className="search-input" style={{ width: "30%" }}>
        <Input
          style={{
            width: "100%",
          }}
          name="name"
          placeholder="Nhập từ khóa"
          onChange={handleSearch}
        />
      </div>
      <div className="btn-search">
        <Button
          onClick={handleSubmitSearch}
          type="primary"
          style={{
            padding: "0 40px ",
            borderRadius: "14px",
            background: "#019db4",
          }}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default Search;
