import React from 'react';
import AllOrder from '../AllOrders';

const CancelOrder = () => {
    return (
        <div>
            <AllOrder isCancel={true} />
        </div>
    );
};

export default CancelOrder;
