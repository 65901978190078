import { Modal } from "antd";
import { useState } from "react";
import CardInvestment from "./CardInvestment";
import CardMember from "./CardMember";
import "./InvestmentSolutions.scss";
import RegisterConsultant from "./RegisterConsultant";
const InvestmentSolutions = () => {
  const [showModalReg, setShowModalReg] = useState(false);

  return (
    <div className="investment-solutions-wrapper">
      <div className="list-investment">
        <CardInvestment setShowModalReg={setShowModalReg} />
        <CardInvestment />
        <CardInvestment />
      </div>
      <div className="member-group">
        <h3>Thành viên cố vấn</h3>
        <div className="list-member">
          <CardMember setShowModalReg={setShowModalReg} />
          <CardMember />
          <CardMember />
          <CardMember />
        </div>
      </div>
      <Modal
        // style={{ borderRadius: "12px" }}
        visible={showModalReg}
        onCancel={() => setShowModalReg(false)}
        footer={null}
        wrapClassName="modal-cus modal-register-consultant"
        title="Đăng ký thông tin để được tư vấn đầu tư"
        centered
      >
        <RegisterConsultant />
      </Modal>
    </div>
  );
};

export default InvestmentSolutions;
