import classNames from 'classnames/bind';
import styles from './DefaultHeaderLayout.scss';
import Footer from '../components/Footer';
import HeaderUser from '../components/HeaderUser';
import banner2 from '~/assets/images/introduce/bannerfair2.png';
import banner1 from '~/assets/images/introduce/bannerfair1.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const cx = classNames.bind(styles);
function DefaultHeaderLayout({ children }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        autoplay: true,
        rows: 1,
    };

    return (
        <div className={cx('wrapper')}>
            <HeaderUser />
            <div className="slider-group">
                <Slider {...settings}>
                    <div className="image-group">
                        <img src={banner1} alt="" />
                    </div>
                    <div className="image-group">
                        <img src={banner2} alt="" />
                    </div>
                </Slider>
            </div>
            <div className={cx('container')}>
                <div>{children}</div>
            </div>
            <Footer />
        </div>
    );
}

export default DefaultHeaderLayout;
