import { faHeart, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Skeleton, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getAllCourse, likeCourse } from "~/store/Course/courseSlice";
import { dateCommon } from "~/utils/dateCommon";
import formatCash from "~/utils/formatCash";
import "./Courses.scss";

const Courses = () => {
  const [statusSidebar, setStatusSidebar] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listCourses, like_message, loading } = useSelector(
    (state) => state.courseReducer
  );
  const user =
    localStorage.getItem("user") === null
      ? null
      : JSON.parse(localStorage.getItem("user"));
  let [searchParams, setSearchParams] = useSearchParams({
    page_index: 1,
    page_size: 8,
  });

  useEffect(() => {
    dispatch(
      getAllCourse({
        index: searchParams.get("page_index"),
        size: searchParams.get("page_size"),
      })
    );
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (like_message !== "") {
      if (like_message === "liked") {
        message.success(t("text.Add_to_favorite_courses"));
      }
      if (like_message === "remove") {
        message.success(t("text.Removed_from_favorite_courses"));
      }
      if (like_message === "error") {
        message.error(t("text.Add_to_favorite_courses_failed"));
      }
    }
  }, [like_message]);

  const itemSidebar = [
    {
      id: 1,
      title: t("text.Startup_training_videos"),
    },
    {
      id: 2,
      title: t("text.Startup_training_posts"),
    },
  ];

  const handleLikeCourse = async (value) => {
    dispatch(
      likeCourse({
        course_id: value,
      })
    );
  };

  const handlePagination = async (page, pageSize) => {
    setSearchParams({ page_index: page, page_size: pageSize, key: "course" });
  };

  return (
    <div className="courses-wrapper">
      <div className="train-post"></div>
      <div className="train-courses">
        <div className="train-courses__left">
          <div className="train-courses__left-bottom">
            {itemSidebar.map((item) => {
              return (
                <div
                  className={statusSidebar === item.id ? "active" : ""}
                  key={item.id}
                  onClick={() => setStatusSidebar(item.id)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
        <div className="train-courses__right">
          <div className="train-courses__list">
            <Skeleton loading={loading}>
              {listCourses?.data?.map((item) => {
                return (
                  <div className="train-courses__item" key={item.id}>
                    <img
                      onClick={() => {
                        !user
                          ? message.info(t("text.Login_required"))
                          : navigate(`/course-register/${item.id}`);
                      }}
                      src={
                        item.image_url === undefined
                          ? "https://iabm.edu.vn/wp-content/uploads/2019/07/ke-toan-doanh-nghiep.jpg"
                          : `${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`
                      }
                      alt="Avatar"
                    />
                    <div className="tranin-courses_details">
                      <div className="train-course_name-group">
                        <span
                          className="train-course__name"
                          onClick={() => {
                            user === null
                              ? message.info(t("text.Login_required"))
                              : navigate(`/course-register/${item.id}`);
                          }}
                        >
                          {item.name}
                        </span>
                        <FontAwesomeIcon
                          onClick={() => handleLikeCourse(item.id)}
                          className="heart-icon"
                          style={{
                            color: item.is_like === 1 ? "red" : "#000",
                          }}
                          icon={faHeart}
                        />
                      </div>
                      <div className="train-course__bottom">
                        <div className="left-group">
                          <div className="train-course__price">
                            {item.course_type === 1 ? (
                              <div style={{ color: "red" }}>
                                {" "}
                                {formatCash(item.price.toString())}
                              </div>
                            ) : (
                              <div style={{ color: "green" }}> Miễn phí</div>
                            )}
                          </div>
                          <div className="train-course__location">
                            <FontAwesomeIcon icon={faLocationDot} />{" "}
                            {item.address}
                          </div>
                        </div>
                        <div className="date-group">
                          <div className="date-month">
                            {t("text.Month")}{" "}
                            {item.begin_date === undefined
                              ? ""
                              : item.begin_date.substring(5, 7)}
                          </div>
                          <div className="date-detail">
                            <div className="date-num">
                              {item.begin_date === undefined
                                ? ""
                                : item.begin_date.substring(8, 10)}
                            </div>
                            <div className="date-day">
                              {dateCommon[item.start_date]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Skeleton>
          </div>
          <div className="pagination">
            <Pagination
              onChange={handlePagination}
              current={parseInt(searchParams.get("page_index"))}
              pageSize={searchParams.get("page_size")}
              total={listCourses?.total_items}
              showSizeChanger
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
