import { useNavigate } from "react-router-dom";
import images from "~/assets/images/home/header";
import formatCash from "~/utils/formatCash";
import "./CardProductV2.scss";

const CardProductV2 = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className="card-product-wrap">
      <div
        className="image-group"
        onClick={() => {
          navigate(`/stores/viewstore/detail-product/${item.id}`);
        }}
      >
        <img
          src={
            item.image_url === undefined
              ? ""
              : `${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`
          }
          alt="Error"
        />
      </div>
      <div className="product-detail">
        <div className="prod-category">
          <span>{item?.category?.name}</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", height: "120px" }}
        >
          <div style={{ paddingBottom: "16px" }}>
            <div
              className="prod-name"
              onClick={() => {
                navigate(`/stores/viewstore/detail-product/${item.id}`);
              }}
            >
              {item?.name}
            </div>
          </div>
          <div
            style={{
              marginTop: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            {item?.price === 0 ? (
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "1.6rem",
                  lineHeight: "2rem",
                  color: "red",
                }}
              >
                {" "}
                Liên hệ
              </div>
            ) : (
              <div className="prod-price">
                <span className="original">
                  {formatCash(item?.original_price.toString())}đ
                </span>
                <span style={{ color: "red" }}>
                  {formatCash(item?.price.toString())}đ
                </span>
              </div>
            )}

            <div className="rate-group">
              <img src={images.rateIcon} alt="" /> 4.7
            </div>
            <div className="prod-store-group">
              <img src={images.shopIcon} alt="" width="16px" />{" "}
              <span> {item?.store?.name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProductV2;
