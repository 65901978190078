import { useParams } from "react-router-dom";
import CardTopProject from "./CardTopProject";
import "./ProjectDetail.scss";
import ProjectDetailContainer from "./ProjectDetailContainer";
import ListProject from "./ProjectDetailContainer/ListProject";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject, getProjectById } from "~/store/Projects/projectsSlice";
import { useEffect } from "react";
import OverviewProject from "./ProjectDetailContainer/OverviewProject";
import { useState } from "react";

const ProjectDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectById } = useSelector((state) => state.projectReducer);

  useEffect(() => {
    dispatch(getProjectById(id));
  }, [dispatch, id]);
  const [projectItem, setProjectItem] = useState([
    {
      id: 1,
      title: "Tổng quan dự án",
      active: true,
      comp: <OverviewProject />,
    },
    // {
    //   id: 2,
    //   title: "Chiến lược kinh doanh",
    //   active: false,
    //   comp: <BusinessStrategy />,
    // },
    // {
    //   id: 3,
    //   title: "Quá trình gây quỹ",
    //   active: false,
    //   comp: <FundraisingProcess />,
    // },
  ]);

  const handleChangeItem = (item) => {
    setProjectItem(
      projectItem.map((projItem) => {
        if (projItem.id === item.id) {
          return {
            ...projItem,
            active: true,
          };
        } else {
          return {
            ...projItem,
            active: false,
          };
        }
      })
    );
  };
  return (
    <div className="project-detail-wrapper">
      <div className="top-detail">
        <CardTopProject projectById={projectById} />
      </div>
      <div className="project-detail-container">
        <div className="project-detail-container-inner">
          <div className="project-tab">
            <ul>
              {projectItem.map((item) => {
                return (
                  <li
                    className={item.active && "active"}
                    onClick={() => {
                      handleChangeItem(item);
                    }}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="project-content">
            {projectItem.map((item) => {
              if (item.active) {
                return <div key={item.id}>{item.comp}</div>;
              } else return <div key={item.id}></div>;
            })}
          </div>
        </div>
      </div>
      <div className="list-project-wrapper">
        <h2>Các dự án có thể bạn thích</h2>
        <ListProject />
      </div>
    </div>
  );
};

export default ProjectDetail;
