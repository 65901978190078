import React from 'react';
import ListCourses from '~/layouts/AdminLayout/CoursesManage/ListCourses';

const ListCourseBusiness = () => {
    return (
        <div>
            <ListCourses />
        </div>
    );
};

export default ListCourseBusiness;
