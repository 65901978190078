import axios from 'axios';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import emptyIcon from '~/assets/images/Products/empty.svg';
import uploadIcon from '~/assets/images/Products/upload.svg';
import { getAllEvents } from '~/store/Categories/eventSlice';
import { notifyError, notifySuccess } from '~/toast/toast';
import documentApi from '~/api/Document/documents.Api';
import './CreateAlbum.scss';

const { Option } = Select;

const CreateAlbum = () => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { eventList } = useSelector((state) => state.eventsReducer);
    const [imagesData, setImagesData] = useState({
        image_url: [],
    });

    useEffect(() => {
        dispatch(getAllEvents());
    }, [dispatch]);

    let formData = new FormData();

    const handleFilesChange = async (e) => {
        setFiles(e.target.files);
        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setFiles([]);
            setImagesData({
                ...imagesData,
                image_url: imagesData.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
        } catch (error) {
            console.log(error);
        }
    };

    const removeImage = (position) => {
        setImagesData({
            ...imagesData,
            image_url: imagesData.image_url.filter((_, index) => position !== index),
        });
    };

    const isEmpty = (
        <div className="is-empty">
            <h3>Hiện tại chưa có ảnh</h3>
            <img src={emptyIcon} alt="empty" />
            <input
                type="file"
                multiple
                accept="img/*"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            />
            <label style={{ cursor: 'pointer' }} htmlFor="file-upload">
                Thêm ảnh
            </label>
        </div>
    );

    const isNotEmpty = (arr) => {
        return (
            <div className="image-container">
                <div className="big-image">
                    <div className="delete-image" onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={`${process.env.REACT_APP_API_URL}/${arr[0].url}`} alt="big" />
                </div>
                <div className="small-image-container">
                    {arr.map((item, index) => {
                        if (index !== 0) {
                            return (
                                <div
                                    className="small-image"
                                    key={item.url}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                    }}
                                >
                                    <div className="delete-image" onClick={() => removeImage(index)}>
                                        X
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                    <div className="small-image upload">
                        <input
                            className="item__input"
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>Thêm ảnh</h4>
                            <img src={uploadIcon} alt="upload" />
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const onFinish = async (value) => {
        const data = {
            event_id: value.event,
            image_url: imagesData.image_url.map((item, index) => {
                if (item.url) return item.url;
                return item;
            }),
            type: 1,
        };

        const res = await documentApi.create(data);
        if (res.status === 400) {
            notifyError(t('text.add_material_failed'));
        } else {
            notifySuccess(t('text.add_material_success'));
            setTimeout(() => {
                navigate('/list-documents');
            }, 3000);
        }
    };

    return (
        <div className="create-album-wrapper">
            <div className="inner">
                <div className="header-main">
                    <div className="header-title">
                        <span>Quản lý tài liệu</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>Tạo album</span>
                    </div>
                </div>
                <div className="content-main">
                    <Form name="form-create-album" layout="vertical" onFinish={onFinish}>
                        <Form.Item name="event" label="Sự kiện">
                            <Select placeholder="Chọn sự kiện">
                                {eventList.map((item) => {
                                    return <Option value={item.id}>{item.name}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name="image_group" label="Chọn ảnh">
                            <div className="product-image__content">
                                {imagesData.image_url === null || imagesData.image_url?.length === 0
                                    ? isEmpty
                                    : isNotEmpty(imagesData.image_url)}
                            </div>
                        </Form.Item>
                        <div className="action-submit">
                            <Button htmlType="submit" type="primary">
                                Tạo album
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CreateAlbum;
