import { Button, Col, Form, Input, Row, Select, Table, message } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getListContact } from "~/store/Projects/projectsSlice";
import { statusInvestment } from "~/utils/statusInvestment";
import "./ListContact.scss";
import { projectsApi } from "~/api/Projects/projectsApi";
const { Option } = Select;

const ListContact = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { listContact } = useSelector((state) => state.projectReducer);

  useEffect(() => {
    dispatch(getListContact(id));
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    render: (text, record, index) => {
      if (dataIndex === "full_name") {
        return record?.contributor?.full_name;
      }
      if (dataIndex === "email") {
        return record?.contributor?.email;
      }
      if (dataIndex === "phone") {
        return record?.contributor?.phone;
      }
      if (dataIndex === "money") {
        if (record?.contributor?.type === 1) {
          return "Từ 2 đến 100 triệu";
        } else {
          return "Trên 100 triệu";
        }
      }
      if (dataIndex === "status") {
        return statusInvestment[record?.contributor?.status];
      }
      if (dataIndex === "contribution_money") {
        return record?.contributor?.contribution_money;
      }
    },
  });

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      key: "full_name",
      title: "Khách hàng",
      dataIndex: "full_name",
      width: "20%",
      align: "center",
      ...getColumnSearchProps("full_name"),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      align: "center",
      width: "16%",
      ...getColumnSearchProps("email"),
    },
    {
      key: "phone",
      title: "Số điện thoại",
      dataIndex: "phone",
      width: "16%",
      align: "center",
      ...getColumnSearchProps("phone"),
    },
    {
      key: "money",
      title: "Số tiền dự định đầu tư",
      dataIndex: "money",
      width: "20%",
      align: "center",
      ...getColumnSearchProps("money"),
    },
    {
      key: "contribution_money",
      title: "Số tiền đã đầu tư",
      dataIndex: "contribution_money",
      width: "14%",
      align: "center",
      ...getColumnSearchProps("contribution_money"),
    },
    {
      key: "status",
      align: "center",
      title: "Trạng thái",
      width: "14%",
      ...getColumnSearchProps("status"),
    },
  ];

  const onSubmit = async (value, record) => {
    const dataSubmit = {
      full_name: record?.contributor?.full_name,
      phone: record?.contributor?.phone,
      email: record?.contributor?.email,
      type: record?.contributor?.type,
      note: record?.contributor?.note,
      project_id: record?.id,
      ...value,
    };
    const res = await projectsApi.createContributor(dataSubmit);
    if (res.status === 200) {
      message.success("Success !!!");
    } else {
      message.error("Update Fail");
    }
    // dispatch(editProject({ id: record?.contributor?.id, body: dataSubmit, project_id: id }));
  };

  return (
    <div className="list-contact-wrapper">
      <Table
        scroll={{ x: 1098 }}
        rowKey={(record) => record?.contributor?.id}
        columns={columns}
        dataSource={listContact}
        expandable={{
          expandedRowRender: (record) => (
            <div className="expanded-contact">
              <Form onFinish={(value) => onSubmit(value, record)}>
                <div className="top-expanded">
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <h3>Ghi chú khách hàng</h3>
                      <span>{record?.contributor?.note}</span>
                    </Col>
                    <Col span={8}>
                      <h3>Số tiền đã đầu tư</h3>
                      <Form.Item name="contribution_money">
                        <Input placeholder="Nhập số tiền" />
                      </Form.Item>
                    </Col>
                    <Col span={8} className="row-group">
                      <div className="col-group">
                        <h3>Ghi chú tư vấn</h3>
                        <Form.Item name="advisory_notes">
                          <Input.TextArea />
                        </Form.Item>
                      </div>
                      <div className="col-group">
                        <h3>Cập nhập trạng thái</h3>
                        <Form.Item name="status">
                          <Select
                            style={{ width: "100%" }}
                            defaultValue={record?.contributor?.status}
                            key={record?.contributor?.id}
                          >
                            <Option value={1}>Chờ tư vấn</Option>
                            <Option value={2}>Đã tư vấn</Option>
                            <Option value={3}>Đã đầu tư</Option>
                            <Option value={4}>Không liên lạc được</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="bottom-expanded">
                  <Button type="primary" htmlType="submit">
                    Cập nhật
                  </Button>
                </div>
              </Form>
            </div>
          ),
        }}
        bordered
        pagination={{
          defaultPageSize: 50,
          hideOnSinglePage: true,
          pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        }}
      />
    </div>
  );
};

export default ListContact;
