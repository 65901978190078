import React from "react";
import { useNavigate } from "react-router-dom";
import "./CardCompany.scss";
import image from "../../../../assets/images/logo/nen.jpg";

const CardCompany = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: "#ffffff",
        backgroundSize: "cover",
      }}
      className="card-company-wrapper"
    >
      <div className="logo-title">
        <div className="image-group">
          <img
            src={
              item?.image_url.length === 0 || item?.image_url === undefined
                ? "https://haymora.com/upload/files/cong_nghe_thong_tin/hivetech/hivetech-logo.jpg"
                : `${process.env.REACT_APP_API_URL}${item?.image_url[0].url}`
            }
            alt=""
          />
        </div>
      </div>
      <div className="wrap-text">
        <div>
          <div className="title-group">{item.name}</div>
          <div className="company-detail">
            <div className="address1">
              Địa chỉ:{" "}
              <span style={{ color: "#838383", fontWeight: "400" }}>
                {" "}
                {item?.address?.address}
              </span>
            </div>
            <div>
              Số điện thoại:{" "}
              <span style={{ color: "#838383", fontWeight: "400" }}>
                {" "}
                {item.phone}
              </span>
            </div>
            <div>
              E-mail:{" "}
              <span style={{ color: "#838383", fontWeight: "400" }}>
                {item.email}
              </span>
            </div>
          </div>
        </div>
        <div className="see-detail">
          <button
            onClick={() => {
              navigate(`/company/${item.id}`);
            }}
          >
            Xem chi tiết
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardCompany;
