import { Button, Image, Popconfirm, Space, Table, message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  getProjectAllPaging,
  getProjectByStore,
} from "~/store/Projects/projectsSlice";
import { getMyStore } from "~/store/Store/storeSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProductByStore } from "~/store/Products/productsSlice";

const ProjectManage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleEditForm = (item) => {
    console.log("item", item);
    navigate(`/businessadmin/edit-project/${item.id}`);
  };
  const handleDelete = (item) => {
    dispatch(deleteProject(item.id)).then((res) => {
      console.log("res", res);
      if (res.type === "project/deleteProject/fulfilled") {
        message.success("Xóa thành công");
        dispatch(
          getProjectAllPaging({
            index: searchParams.get("page_index"),
            size: searchParams.get("page_size"),
          })
        );
      }
    });
    console.log("item", item);
  };
  const [storeId, setStoreId] = useState(0);
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams({
    page_index: 1,
    page_size: 5,
  });

  useEffect(() => {
    dispatch(getMyStore()).then((res) => {
      console.log(res);
      if (res?.type === "store/getMyStore/fulfilled") {
        setStoreId(res.payload.id);
      }
    });
    // dispatch(getMyStore());
  }, []);

  useEffect(() => {
    if (storeId) {
      dispatch(
        getProjectByStore({
          id: storeId,
          index: searchParams.get("page_index"),
          size: searchParams.get("page_size"),
        })
      );
    }
  }, [storeId, searchParams, dispatch]);

  const { listProject } = useSelector((state) => state.projectReducer);
  const { storeById } = useSelector((state) => state.storesReducer);
  // console.log("store id", storeId);
  function formatCurrency(amount) {
    return amount?.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
  }
  // console.log("lisst", listProjectAllPaging);

  const dataSource = listProject?.data?.map((item, index) => ({
    key: index,
    id: item?.id,
    name: item?.name,
    image_url: item?.image_url?.[0]?.url,
    amount_called: formatCurrency(item?.amount_Called),
    amount_call: formatCurrency(item?.amount_call),
    cover_image: item?.cover_image?.[0]?.url,
    customer_id: item?.customer_id?.email,
  }));

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      align: "center",
      render: (index) => index + 1,
    },
    {
      key: "image_url",
      title: "Ảnh",
      dataIndex: "image_url",
      align: "center",
      width: "200px",
      render: (img) => (
        <Image
          src={`https://api.ecommerce.smiletech.vn:8443/${img}`}
          alt="Ảnh"
          height={60}
          style={{
            width: "auto",
          }}
        />
      ),
    },
    {
      key: "name",
      title: "Tên dự án",
      dataIndex: "name",
      width: "300px",
      align: "center",
    },
    {
      key: "amount_called",
      title: "Đã kêu gọi",
      dataIndex: "amount_called",
      align: "center",
    },
    {
      key: "amount_call",
      title: "Kêu gọi",
      dataIndex: "amount_call",
      align: "center",
      width: "200px",
    },
    {
      key: "customer_id",
      title: "Liên hệ đầu tư",
      width: "200px",
      dataIndex: "customer_id",
      align: "center",
    },

    {
      key: "action",
      align: "center",
      title: "Action",
      width: "200px",

      render: (text, record, index) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEditForm(record)} />
          <Popconfirm
            title="delete this record?"
            onConfirm={() => handleDelete(record)}
          >
            <Button icon={<DeleteOutlined />} type="dashed"></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="project-manage-wrapper">
      <Table
        scroll={{ x: 1098 }}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={{
          defaultPageSize: 50,
          hideOnSinglePage: true,
          pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        }}
      />
    </div>
  );
};

export default ProjectManage;
