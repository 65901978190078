import { Fragment, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  publicRoutes,
  sideBarRoutes,
  privateBusinessAdminRoutes,
  privateUserRoutes,
} from "~/routes";
import AdminLayout from "~/components/layouts/AdminLayouts";
import DefaultHeaderLayout from "~/components/layouts/DefaultHeaderLayout";
import DefaultBusinessAdminLayout from "./components/layouts/DefaultBusinessAdminLayout";
import DefaultUserLayout from "./components/layouts/DefaultUserLayout";
import RequireAuth from "./routes/RequireAuth";

import Error from "./pages/Error";

function App() {
  // const user = localStorage.getItem('user') === null ? null : JSON.parse(localStorage.getItem('user'));
  const user = 1;
  const PublicLayout = publicRoutes.map((route, index) => {
    const Page = route.component;
    let Layout = DefaultHeaderLayout;
    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  const HighestAdminLayout = sideBarRoutes.map((route, index) => {
    const Page = route.component;
    let LayoutAdmin = AdminLayout;
    if (route.layout) {
      LayoutAdmin = route.layout;
    } else if (route.layout === null) {
      LayoutAdmin = Fragment;
    }
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <LayoutAdmin>
            <Page />
          </LayoutAdmin>
        }
      />
    );
  });

  const BusinessAdminLayout = privateBusinessAdminRoutes.map((route, index) => {
    const Page = route.component;
    let LayoutAdmin = DefaultBusinessAdminLayout;
    if (route.layout) {
      LayoutAdmin = route.layout;
    } else if (route.layout === null) {
      LayoutAdmin = Fragment;
    }
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <LayoutAdmin>
            <Page />
          </LayoutAdmin>
        }
      />
    );
  });

  const UserLayout = privateUserRoutes.map((route, index) => {
    const Page = route.component;
    let LayoutAdmin = DefaultUserLayout;
    if (route.layout) {
      LayoutAdmin = route.layout;
    } else if (route.layout === null) {
      LayoutAdmin = Fragment;
    }
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <LayoutAdmin>
            <Page />
          </LayoutAdmin>
        }
      />
    );
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <div className="App">
          <Routes>{PublicLayout}</Routes>
          <Routes element={<RequireAuth />}>
            {user && user !== null ? (
              <>
                {BusinessAdminLayout}
                {UserLayout}
              </>
            ) : (
              <></>
            )}
            {user && user !== null ? (
              <>
                {HighestAdminLayout}
                {BusinessAdminLayout}
                {UserLayout}
              </>
            ) : (
              <></>
            )}
            {user && user !== null ? (
              <>
                {HighestAdminLayout}
                {BusinessAdminLayout}
                {UserLayout}
              </>
            ) : (
              <></>
            )}
            {user && user !== null ? UserLayout : <></>}
          </Routes>
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
