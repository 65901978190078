import { Divider, Image } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentAllPaging } from '~/store/Documents/documentsSlice';
import './ListAlbum.scss';
import ListAlbumDocument from './ListAlbumDocument';
import AlbumDetails from './AlbumDetails';

const ListAlbum = ({idSelect}) => {

    const album = [
        {
            id: 1,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p06.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 2,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p06.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 3,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p06.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 4,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p06.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 5,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p06.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 6,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p06.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        }
    ]

    const albumDetails = [
        {
            id: 1,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 2,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 3,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 4,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 5,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 6,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 7,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        },
        {
            id: 8,
            image: 'https://antmedia.com.vn/wp-content/uploads/2020/07/le-ky-hop-dong-ho-p04.jpg',
            name: 'Sự kiện hợp tác SmileTech và ConnectUp'
        }
    ]

    const [textDetailAlbum, setTextDetailAlbum] = useState('')

    function Nhan(detailAlbum) {
        setTextDetailAlbum(detailAlbum)
    }

    return (
        <>
            <h2>{!textDetailAlbum ? '' : 'Sự kiện hợp tác SmileTech và ConnectUp' }</h2>
            <div className='container-album'>
                {!textDetailAlbum 
                ? album.map((item) => <ListAlbumDocument key={item.id} listData={item} nhan={Nhan} />)
                : albumDetails.map((item) => <AlbumDetails id={idSelect} key={item.id} listData={item}/>)
                }
                {/* {idSelect === 2 && album.map((item) => <ListAlbumDocument key={item.id} listData={item} nhan={Nhan} />)} */}
            </div>
        </>
    )
    // const dispatch = useDispatch();
    // const { documentsAllPaging } = useSelector((state) => state.documentsReducer);
    // const [dataImage, setDataImage] = useState([]);
    // const [visible, setVisible] = useState(false);
    // useEffect(() => {
    //     dispatch(
    //         getDocumentAllPaging({
    //             index: 1,
    //             size: 10,
    //             type: 1,
    //         }),
    //     );
    // }, [dispatch]);

    // const handlePreview = (parItem) => {
    //     setDataImage(
    //         parItem.image_url.map((imageItem, index) => {
    //             return <Image src={`${process.env.REACT_APP_API_URL}${imageItem.url}`} key={index} />;
    //         }),
    //     );
    // };

    // return (
    //     <div className="list-album-wrapper">
    //         {documentsAllPaging?.data?.map((parItem, index) => {
    //             return (
    //                 <div className="album-row" key={index}>
    //                     <div className="top-album">
    //                         <div className="album-title">{parItem?.event?.name}</div>
    //                         <div
    //                             className="see-detail"
    //                             onClick={() => {
    //                                 handlePreview(parItem);
    //                                 setVisible(true);
    //                             }}
    //                         >
    //                             Xem thêm
    //                         </div>
    //                         <div
    //                             style={{
    //                                 display: 'none',
    //                             }}
    //                         >
    //                             <Image.PreviewGroup
    //                                 preview={{
    //                                     visible,
    //                                     onVisibleChange: (vis) => setVisible(vis),
    //                                 }}
    //                             >
    //                                 {dataImage}
    //                             </Image.PreviewGroup>
    //                         </div>
    //                     </div>
    //                     <div className="image-group">
    //                         {parItem.image_url.map((imageItem, index) => {
    //                             return (
    //                                 <div key={index}>
    //                                     <div className="image-item">
    //                                         <img src={`${process.env.REACT_APP_API_URL}${imageItem.url}`} alt="" />
    //                                     </div>
    //                                 </div>
    //                             );
    //                         })}
    //                     </div>
    //                 </div>
    //             );
    //         })}
    //     </div>
    // );
};

export default ListAlbum;
