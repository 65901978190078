import {
  faFacebook,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FairDetail.scss";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import images from "~/assets/images/home/header";
import { Col, Divider, Modal, Row } from "antd";
import ShoppingMall from "../../introduce/ShoppingMall";
import { useState } from "react";
import RegStore from "./RegStore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllEventsWithPag2,
  getEventById,
} from "~/store/Categories/eventSlice";
import parse from "html-react-parser";

import { useParams } from "react-router-dom";

const FairDetail = () => {
  const { id } = useParams();
  const [showModalRegStore, setShowModalRegStore] = useState(false);
  const [eventType, setEventType] = useState(false);
  const { eventListPag2, eventById } = useSelector(
    (state) => state.eventsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllEventsWithPag2({
        type: 5,
        index: 1,
        size: 2,
      })
    );
    dispatch(getEventById(id));
  }, [id]);

  console.log("eventListPag2", eventListPag2);

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  return (
    <div className="fair-detail-wrapper">
      <h3>{eventById?.name}</h3>
      <div className="social-btn">
        <button className="facebook-btn">
          <FontAwesomeIcon icon={faFacebook} /> Chia sẻ Facebook
        </button>
      </div>
      <div className="fair-status">
        <div className="dot-icon"></div>
        <span style={{ color: "#39B54A" }}>Đang diễn ra</span>
      </div>
      <div className="time-group">
        Thời gian:
        <span>
          {formatDate(eventById?.start_time)} -{" "}
          {formatDate(eventById?.end_time)}
        </span>
      </div>

      <div className="time-group time2">
        Địa điểm:<span>{eventById.address}</span>
      </div>

      <div className="fair-register">
        <button
          onClick={() => {
            setShowModalRegStore(true);
          }}
        >
          Đăng ký gian hàng
        </button>
        <button
          onClick={() => {
            setShowModalRegStore(true);
            setEventType(true);
          }}
        >
          Đăng ký tham quan
        </button>
      </div>

      <div className="fair-content">{parse(`${eventById?.content}`)}</div>
      <Divider plain>
        <h2>Hội chợ - Triển lãm nổi bật</h2>
      </Divider>
      <div className="fair-popular">
        <Row gutter={[24, 24]}>
          {eventListPag2.map((item) => {
            return (
              <Col span={8}>
                <ShoppingMall item={item} />
              </Col>
            );
          })}
        </Row>
      </div>
      <Modal
        title="Đăng ký gian hàng"
        visible={showModalRegStore}
        // onOk={handleOk}
        onCancel={() => {
          setShowModalRegStore(false);
          setEventType(false);
        }}
        footer={null}
        className="modal-reg-store"
      >
        <RegStore eventType={eventType} />
      </Modal>
    </div>
  );
};

export default FairDetail;
