import { Button, Form, Input, Select } from "antd";
import "./RegisterConsultant.scss";
const { Option } = Select;

const RegisterConsultant = () => {
  const onFinish = (value) => {
    const data = {
      name: value.name,
      email: value.email,
      phone: value.phone,
      money: value.money,
      area: value.area,
    };
  };

  return (
    <div className="register-consultant">
      <Form
        name="form-register-consultant"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Họ và tên"
          rules={[
            {
              required: true,
              message: "Họ và tên không được để trống",
            },
          ]}
        >
          <Input placeholder="Nhập họ tên" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Họ và tên không được để trống",
            },
            {
              type: "email",
              message: "Email không đúng định dạng",
            },
          ]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Số điện thoại"
          rules={[
            {
              required: true,
              message: "Họ và tên không được để trống",
            },
            {
              pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
              message: "Số điện thoại không đúng định dạng",
            },
          ]}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item
          name="money"
          label="Số tiền dự định đầu tư"
          rules={[
            {
              required: true,
              message: "Chọn số tiền dự định đầu tư",
            },
          ]}
        >
          <Select placeholder="Số tiền dự định đầu tư">
            <Option value="1">Từ 2 triệu đến dưới 100 triệu đồng</Option>
            <Option value="2">Trên 100 triệu đồng</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="area"
          label="Khu vực"
          rules={[
            {
              required: true,
              message: "Bạn phải chọn khu vực",
            },
          ]}
        >
          <Select placeholder="Khu vực">
            <Option value="1">Miễn Bắc</Option>
            <Option value="2">Miễn Trung</Option>
            <Option value="3">Miền Nam</Option>
          </Select>
        </Form.Item>
        <div className="send-btn">
          <Form.Item style={{ marginBottom: "unset" }}>
            <Button
              style={{
                fontSize: "14px",
                padding: "8px 24px",
                textAlign: "center",
                height: "auto",
                borderRadius: "30px",
                background: "#019DB4",
                color: "#fff",
                cursor: "pointer",
              }}
              htmlType="submit"
            >
              Gửi thông tin
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default RegisterConsultant;
