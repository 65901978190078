import classNames from 'classnames/bind';
import styles from './CreatePost.module.scss';
import CreatePost from '../CreatePost';

const cx = classNames.bind(styles);

const EditPost = () => {
    return (
        <div className={cx('wrapper')}>
            <CreatePost isEdit={true} />
        </div>
    );
};

export default EditPost;
