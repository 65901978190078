import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ListProject.scss";
import CardProject from "~/components/layouts/components/CardProject";
import {
  getAllProject,
  getProjectAllPaging,
} from "~/store/Projects/projectsSlice";
import { useParams } from "react-router-dom";

const ListProject = () => {
  const { id } = useParams;
  const dispatch = useDispatch();
  const { listProjectAllPaging } = useSelector((state) => state.projectReducer);

  useEffect(() => {
    dispatch(
      getProjectAllPaging({
        index: 1,
        size: 8,
      })
    );
  }, [dispatch]);
  const relatedProject = listProjectAllPaging?.data?.filter(
    (project) => project.id !== id
  );
  console.log("listProject", relatedProject);
  return (
    <div className="list-project">
      {relatedProject?.map((item, index) => {
        return <CardProject key={index} item={item} />;
      })}
    </div>
  );
};

export default ListProject;
