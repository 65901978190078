import { Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getStoreAllPaging } from "~/store/Store/storeSlice";
import CardCompany from "./CardCompany";
import Search from "./Search";
import "./Company.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Company = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { listStoreAllPaging, loading } = useSelector(
    (state) => state.storesReducer
  );
  console.log("listStoreAllPaging", listStoreAllPaging);
  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      dispatch(
        getStoreAllPaging({
          page: {
            index: 1,
            size: 100,
          },
          data: {
            field_id: id,
            city_id: "",
            name: "",
          },
        })
      );
    } else {
      dispatch(
        getStoreAllPaging({
          page: {
            index: 1,
            size: 100,
          },
          data: {
            field_id: "",
            city_id: "",
            name: "",
          },
        })
      );
    }
  }, [id]);

  return (
    <div className="company-wrapper">
      <Spin spinning={loading}>
        <div className="company-inner">
          <div className="search-group">
            <div
              style={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "28px",
              }}
            >
              {" "}
              {t("homecustomer.stores")}
            </div>
            <Search />
          </div>
          <div className="list-company">
            {listStoreAllPaging?.data?.length === 0 ? (
              <h3>Không tìm thấy doanh nghiệp nào phù hợp</h3>
            ) : (
              listStoreAllPaging?.data?.map((item) => {
                return <CardCompany item={item} key={item.id} />;
              })
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Company;
