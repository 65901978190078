import { Col, Row } from "antd";

import ShoppingMall from "../../introduce/ShoppingMall";
import "./ListSemilar.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllEventsWithPag,
  getAllEventsWithPag2,
} from "~/store/Categories/eventSlice";
import CardEvent from "../../introduce/CardEvent";

const ListSemilar = () => {
  const { eventListPag } = useSelector((state) => state.eventsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllEventsWithPag({
        type: 3,
        index: 1,
        size: 50,
      })
    );
  }, []);

  return (
    <div className="list-fair-wrapper">
      <div className="list-fair-wrap">
        <h1 style={{ marginTop: "18px", marginBottom: "24px" }}>
          HOẠT ĐỘNG, SỰ KIỆN, HỘI THẢO
        </h1>

        <Row style={{ gap: "24px", justifyContent: "space-between" }}>
          {eventListPag.map((item, index) => {
            return <CardEvent item={item} key={item.id} />;
          })}
        </Row>
      </div>
    </div>
  );
};

export default ListSemilar;
