import React from "react";
import {
  FieldTimeOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { Button, Row, Col } from "antd";

const ListEventDocument = ({ listData }) => {
  // console.log(listData);
  return (
    <>
      <div className="item">
        <div className="item-img">
          <img src={listData.image} />
        </div>
        <div className="item-content">
          <p className="text-name">{listData.name}</p>
          <p className="text-ic">
            <FieldTimeOutlined />
            {listData.time}
          </p>
          <p className="text-ic">
            <EyeOutlined />
            {listData.view}
          </p>
          <p className="text-ic">
            <CloudDownloadOutlined />
            {listData.dowload}
          </p>
          <Button icon={<CloudDownloadOutlined />}>Tải về</Button>
        </div>
      </div>
    </>
  );
};

export default ListEventDocument;
