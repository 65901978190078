import axios from 'axios';
import JoditEditor from 'jodit-react';
import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, message, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getAllCategoryNews, getNewsById } from '~/store/News/newsSlice';
import { notifyError, notifySuccess } from '~/toast/toast';
import newsApi from '~/api/NewsApi/newsApi';
import styles from './CreatePost.module.scss';

const { Option } = Select;

const cx = classNames.bind(styles);

const CreatePost = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listCategoryNews, newsById, loading } = useSelector((state) => state.newsReducer);
    const { id } = useParams();
    const [formEdit] = Form.useForm();
    let formData = new FormData();
    const [newsData, setNewsData] = useState({
        name: '',
        description: '',
        content: '',
        image_url: [],
        news_category_id: '',
        ecommerce_id: 69,
    });

    const [newsImage, setNewsImage] = useState([]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getNewsById(id));
        } else {
        }
        dispatch(getAllCategoryNews());
    }, [dispatch, isEdit, id]);

    useEffect(() => {
        if (isEdit) {
            const dataEdit = {
                name: newsById?.name,
                description: newsById.des,
                content: newsById.content,
                news_category_id: newsById?.news_categories?.id,
            };

            formEdit.setFieldsValue(dataEdit);
            setNewsData(newsById);
        }
    }, [newsById]);

    const handleChangeData = (e) => {
        setNewsData((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleSubmit = async (value) => {
        if (isEdit) {
            const dataEdit = {
                name: value.name,
                description: value.description,
                content: value.content,
                image_url: newsData.image_url.map((item) => item.url),
                news_category_id: value.news_category_id,
                ecommerce_id: 69,
            };

            const res = await newsApi.edit(id, dataEdit);
            if (res.message === 'error') {
                message.error('Có lỗi xảy ra');
            } else {
                message.success('Chỉnh sửa thành công');
            }
        } else {
            const data = {
                name: value.name,
                description: value.description,
                content: value.content,
                image_url: newsData.image_url.map((item) => item.url),
                news_category_id: value.news_category_id,
                ecommerce_id: 69,
            };

            const res = await newsApi.createNews(data);
            if (res.message === 'error') {
                message.error('Đăng bài viết lỗi');
            } else {
                message.success('Đăng bài viết thành công');
            }
        }
    };
    const [, setFiles] = useState([]);

    const removeImage = (position) => {
        setNewsData({
            ...newsData,
            image_url: newsData.image_url.filter((_, index) => position !== index),
        });
    };
    const handleFilesChange = async (e) => {
        setFiles(e.target.files);

        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setFiles([]);

            setNewsData({
                ...newsData,
                image_url: newsData.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
            notifySuccess('Upload ảnh thành công');
        } catch (error) {
            notifyError('Upload ảnh không thành công');
        }
    };

    const isEmpty = (
        <div className={cx('is-empty')}>
            <h3>Hiện tại chưa có ảnh</h3>
            <input
                type="file"
                multiple
                accept="img/*"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            />
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                Thêm ảnh
            </label>
        </div>
    );
    const isNotEmpty = (arr) => {
        return (
            <div className={cx('image-container')}>
                <div className={cx('big-image')}>
                    <div className={cx('delete-image')} onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={arr === undefined ? '' : `${process.env.REACT_APP_API_URL}/${arr[0]?.url}`} alt="big" />
                </div>
                <div className={cx('small-image-container')}>
                    {arr === undefined ? (
                        <></>
                    ) : (
                        arr.map((item, index) => {
                            if (index !== 0) {
                                return (
                                    <div
                                        className={cx('small-image')}
                                        key={item.url}
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                        }}
                                    >
                                        <div className={cx('delete-image')} onClick={() => removeImage(index)}>
                                            X
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })
                    )}
                    <div className={cx('small-image upload')}>
                        <input
                            className={cx('item__input')}
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>Thêm ảnh</h4>
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={cx('wrapper')}>
            <Spin spinning={loading}>
                <div className={cx('inner')}>
                    <div className={cx('header-main')}>
                        <div className={cx('header-title')}>
                            <span>{t('text.posts')}</span>
                            <FontAwesomeIcon icon={faAngleRight} />
                            <span>{t('text.create_post')}</span>
                        </div>
                    </div>
                    <div className={cx('content-main')}>
                        <div className={cx('content-main-wrapper')}>
                            <div className={cx('create-post-form')}>
                                <Form name="form-edit" onFinish={handleSubmit} form={formEdit}>
                                    <div className={cx('form-wrapper')}>
                                        <div className={cx('form-group-wrapper')}>
                                            <label htmlFor="name">{t('text.title')}</label>
                                            <Form.Item
                                                className={cx('form-group')}
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Tiêu đề không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t('text.enter_title')}
                                                    value={newsData.name}
                                                    onChange={(e) => handleChangeData(e)}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <label htmlFor="select-group">{t('text.category')}</label>
                                            <Form.Item
                                                style={{ width: '300px' }}
                                                name="news_category_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Tiêu đề không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder={t('text.category')}
                                                    onChange={(value) => {
                                                        setNewsData({
                                                            ...newsData,
                                                            news_category_id: parseInt(value),
                                                        });
                                                    }}
                                                >
                                                    {listCategoryNews.map((item) => {
                                                        return <Option value={item.id}>{item.name}</Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className={cx('form-group-wrapper')}>
                                            <label htmlFor="description">{t('text.description')}</label>
                                            <Form.Item
                                                className={cx('form-group')}
                                                name="description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Mô tả không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea
                                                    style={{
                                                        minHeight: '100px',
                                                    }}
                                                    placeholder={t('text.content')}
                                                    value={newsData.description}
                                                    onChange={(e) => handleChangeData(e)}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={cx('form-group-wrapper')}>
                                            <label htmlFor="">{t('text.content')}</label>
                                            <Form.Item
                                                name="content"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Nội dung không được để trống',
                                                    },
                                                ]}
                                            >
                                                <JoditEditor
                                                    className="content"
                                                    name="content"
                                                    value={newsData.content}
                                                    onChange={(value) => setNewsData({ ...newsData, content: value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className={cx('upload-images')}>
                                            <h3>{t('text.picture')}</h3>
                                            <Form.Item
                                                name="image_url"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ảnh không được để trống',
                                                    },
                                                ]}
                                            >
                                                <div className={cx('main-form_item', 'w100')}>
                                                    {newsData.image_url === null || newsData.image_url?.length === 0
                                                        ? isEmpty
                                                        : isNotEmpty(newsData.image_url)}
                                                </div>
                                            </Form.Item>
                                        </div>
                                        <div className={cx('action-submit')}>
                                            <button className={cx('btn-submit')}>{t('text.post')}</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default CreatePost;
