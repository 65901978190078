import React from "react";
import "./InvestmentFund.scss";
const InvestmentFund = ({ item }) => {
  return (
    <div className="investment-fund-item">
      <div className="image-group">
        <img src={item.image_url} alt="" />
      </div>
      <div className="investment-detail">
        <h2>{item.name}</h2>
        <div
          style={{ display: "flex", flexDirection: "column", height: "120px" }}
        >
          <span>{item.des}</span>
          <a style={{ color: "#019bb3", marginTop: "auto" }} href={item.url}>
            Trang chủ quỹ
          </a>
        </div>
      </div>
    </div>
  );
};

export default InvestmentFund;
