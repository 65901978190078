import CreateConsultant from '../CreateConsultant';
const UpdateConsultant = () => {
    return (
        <div>
            <CreateConsultant isEdit={true} />
        </div>
    );
};

export default UpdateConsultant;
