import { Button, Form, Input, message } from "antd";
import enterpriseApi from "~/api/Enterprise/EnterpriseApi";
import ButtonComponent from "~/components/layouts/components/Button";

const ContactForm = ({ enterpriseId }) => {
  const [formContact] = Form.useForm();

  const validateMessages = {
    required: "${label} không được để trống!",
    types: {
      email: "${label} không đúng định dạng!",
      number: "${label} không đúng định dạng!",
    },
  };

  const onSubmit = async (value) => {
    const res = await enterpriseApi.regContact({
      ...value,
      enterprise_id: enterpriseId,
    });

    if (res.id !== undefined) {
      message.success("Gửi lời nhắn thành công. Vui lòng đợi liên hệ");
      formContact.resetFields();
    } else {
      message.error("Có lỗi xảy ra");
    }
  };

  return (
    <div className="contact-form-wrapper">
      <Form
        layout="vertical"
        onFinish={onSubmit}
        style={{
          width: "100%",
        }}
        form={formContact}
        validateMessages={validateMessages}
      >
        <Form.Item
          className="detail-name"
          name="full_name"
          label="Họ và tên"
          rules={[
            {
              required: true,
              message: "Họ và tên không được để trống",
            },
          ]}
          style={{
            width: "100%",
          }}
        >
          <Input placeholder="Nhập tên" />
        </Form.Item>
        <Form.Item
          className="detail-phone"
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
              message: "Số điện thoại không đúng định dạng",
            },
          ]}
        >
          <Input
            style={{
              width: "100%",
            }}
            placeholder="Nhập số điện thoại"
          />
        </Form.Item>
        <Form.Item
          className="detail-phone"
          name="address"
          label="Địa chỉ"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Nhập địa chỉ" />
        </Form.Item>
        <Form.Item
          className="detail-phone"
          name="email"
          label="Email"
          rules={[
            {
              required: true,
            },
            { type: "email" },
          ]}
        >
          <Input placeholder="Nhập địa chỉ E-mail" />
        </Form.Item>
        <Form.Item
          name="message"
          label="Lời nhắn"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea placeholder="Nhập lời nhắn" />
        </Form.Item>
        <div className="register-btn" style={{ textAlign: "right" }}>
          {/* <Button
            type="primary"
            style={{
              background: "#019db4",
              borderRadius: "30px",
              width: "100px",
              fontSize: "16px",
            }}
            htmlType="submit"
          >
            Gửi
          </Button> */}
          <ButtonComponent
            type="submit"
            primary
            style={{
              background: "#019db4",
              borderRadius: "30px",
              padding: "8px 24px",
              fontSize: "16px",
            }}
            small2
          >
            Gửi
          </ButtonComponent>
        </div>
      </Form>
    </div>
  );
};

export default ContactForm;
