import React from 'react';
import CreateBusiness from '../CreateBusiness';

const EditBusiness = () => {
    return (
        <div>
            <CreateBusiness isEdit={true} />
        </div>
    );
};

export default EditBusiness;
