import React from 'react';
import classNames from 'classnames/bind';
import styles from './ListPosts.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import images from '~/assets/images/home/header';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNews, getAllCategoryNews, getAllNews } from '~/store/News/newsSlice';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination, Popconfirm, Skeleton } from 'antd';

const cx = classNames.bind(styles);

const ListPosts = () => {
    const { t } = useTranslation();
    const { listNews, loading, total_items } = useSelector((state) => state.newsReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams({
        page_index: 1,
        page_size: 5,
    });

    useEffect(() => {
        dispatch(getAllNews({ index: searchParams.get('page_index'), size: searchParams.get('page_size') }));
    }, [searchParams, dispatch]);

    const handlePagination = async (page, pageSize) => {
        setSearchParams({ page_index: page, page_size: pageSize });
    };

    const handleDeleteNews = (item) => {
        dispatch(
            deleteNews({
                id: item,
                body: { index: searchParams.get('page_index'), size: searchParams.get('page_size') },
            }),
        );
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('header-main')}>
                    <div className={cx('header-title')}>
                        <span>{t('text.posts')}</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>{t('text.post_list')}</span>
                    </div>
                    <div className={cx('header-search')}>
                        <div className={cx('search-input')}>
                            <label htmlFor="search-business"></label>
                            <input id={cx('search-business')} placeholder={t('formsearch.btnsearch')}></input>
                        </div>
                        <button className={cx('btn-search')}>{t('formsearch.btnsearch')}</button>
                    </div>
                    <div className={cx('header-create-group')}>
                        <ul>
                            <li className={cx('image')}>{t('text.Avatar')}</li>
                            <li className={cx('time')}>{t('text.Time')}</li>
                            <li className={cx('title')}>{t('text.title')}</li>
                            <li className={cx('type')}>{t('text.type')}</li>
                            <li className={cx('status')}>{t('text.status')}</li>
                        </ul>
                    </div>
                </div>

                <div className={cx('main-content')}>
                    {listNews?.map((item) => {
                        return (
                            <Skeleton loading={loading}>
                                <div key={item.id} className={cx('post-group')}>
                                    <div className={cx('post-avatar')}>
                                        <img
                                            src={
                                                item?.image_url === undefined || item?.image_url.length === 0
                                                    ? ''
                                                    : `${process.env.REACT_APP_API_URL}${item?.image_url[0].url}`
                                            }
                                            alt="Error"
                                        />
                                    </div>
                                    <div className={cx('post-time')}>
                                        <span className={cx('date')}>Ngày: {item?.created_date.substring(0, 10)}</span>
                                    </div>
                                    <div className={cx('post-title')}>
                                        <span>{item?.name}</span>
                                    </div>
                                    <div className={cx('post-type')}>
                                        <span>{item?.news_categories?.name}</span>
                                    </div>
                                    <div className={cx('post-status')}>
                                        <Popconfirm
                                            title="Bạn có chắc muốn xóa không?"
                                            onConfirm={() => handleDeleteNews(item.id)}
                                        >
                                            <button className={cx('comfirmed')}>{t('text.delete')}</button>
                                        </Popconfirm>
                                        <button
                                            className={cx('edit')}
                                            onClick={() => {
                                                navigate(`/postsmanage/edit-post/${item.id}`);
                                            }}
                                        >
                                            {t('text.edit')}
                                        </button>
                                    </div>
                                </div>
                            </Skeleton>
                        );
                    })}
                </div>
            </div>
            <div className={cx('pagination-group')}>
                <Pagination
                    onChange={handlePagination}
                    current={parseInt(searchParams.get('page_index'))}
                    pageSize={searchParams.get('page_size')}
                    total={total_items}
                    showSizeChanger
                />
            </div>
        </div>
    );
};

export default ListPosts;
