import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { getProductByStore } from "~/store/Products/productsSlice";
import CardInforCompany from "./CardInforCompany";
import CompanyProject from "./CompanyProject";
import CardProductV2 from "~/components/layouts/components/CardProductV2";
import { getStoreById } from "~/store/Store/storeSlice";
import "./CompanyDetail.scss";
import {
  getProjectAllPaging,
  getProjectByStore,
} from "~/store/Projects/projectsSlice";

const CompanyDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { productByStore } = useSelector((state) => state.productsReducer);
  const { storeDetail } = useSelector((state) => state.storesReducer);
  const { listProject } = useSelector((state) => state.projectReducer);
  // console.log("productByStore", productByStore);

  useEffect(() => {
    dispatch(
      getProductByStore({
        id: id,
      })
    );
    dispatch(getStoreById(id));
    dispatch(
      getProjectByStore({
        id: id,
        index: 1,
        size: 3,
      })
    );
  }, [dispatch, id]);

  return (
    <div className="company-detail-wrapper">
      <div className="company-detail-inner">
        <div className="card-infor-wrap">
          <CardInforCompany item={storeDetail} />
        </div>
        <div className="company-project">
          <h2>Dự án</h2>
          <div className="list-project">
            {listProject?.data?.map((item) => {
              return <CompanyProject item={item} />;
            })}
            {/* <CompanyProject />
                        <CompanyProject />
                        <CompanyProject /> */}
          </div>
        </div>
        <div className="company-products">
          <h2>Sản phẩm của doanh nghiệp</h2>
          {/* <div className="list-product">
            {productByStore?.data?.length === 0 ? (
              <h3>Chưa có sản phẩm nào trong cửa hàng</h3>
            ) : (
              productByStore?.data?.map((item) => {
                return <CardProductV2 item={item} key={item.id} />;
              })
            )}
          </div> */}

          {productByStore?.data?.length === 0 ? (
            <h3>Chưa có sản phẩm nào trong cửa hàng</h3>
          ) : (
            <div className="list-product">
              {productByStore?.data?.map((item) => {
                return <CardProductV2 item={item} key={item.id} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
