import "./CardInforCompany.scss";

const CardInforCompany = ({ item }) => {
  return (
    <div className="card-infor-company">
      <div className="image-group">
        <img
          src={
            item.image_url === undefined
              ? ""
              : `${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`
          }
          alt=""
        />
      </div>
      <div className="company-infor">
        <div style={{ fontSize: "24px" }}>{item?.name}</div>
        <span>
          Địa chỉ:{" "}
          <span style={{ color: "#838383" }}> {item?.address?.address}</span>
        </span>
        <span>
          Số điện thoại: <span style={{ color: "#838383" }}>{item?.phone}</span>
        </span>
        <span>
          E-mail: <span style={{ color: "#838383" }}>{item?.email}</span>
        </span>
        <span>
          Website: <span style={{ color: "#838383" }}>{item?.website}</span>
        </span>
        <span>
          Lĩnh vực:{" "}
          <span style={{ color: "#838383" }}>{item?.field_id?.name}</span>
        </span>
      </div>
    </div>
  );
};

export default CardInforCompany;
