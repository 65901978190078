import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";

import coursesApi from "~/api/CourseApi/coursesApi";
import onePayApi from "~/api/Onepay/onepay";
import formatCash from "~/utils/formatCash";
import "./TicketRecipient.scss";

const TicketRecipient = ({
  quantityRegis,
  listSubcribers,
  courseData,
  paymentMethod,
  inforReceiveTicket,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const user =
    localStorage.getItem("user") === null
      ? null
      : JSON.parse(localStorage.getItem("user"));
  return (
    <div className="ticket-recipient">
      <div className="ticket-top">
        <div className="recipient-group">
          <h3 className="title">THÔNG TIN NGƯỜI NHẬN VÉ</h3>
          <div className="recipient-detail">
            <div className="form-group">
              <div className="left-detail">
                <FontAwesomeIcon style={{ width: "14px" }} icon={faUser} />
                <label style={{ color: "#000000", fontSize: "14px" }}>
                  Họ tên
                </label>
              </div>
              <div className="right-detail">{user.full_name}</div>
            </div>
            <div className="form-group">
              <div className="left-detail">
                <FontAwesomeIcon style={{ width: "14px" }} icon={faEnvelope} />
                <label style={{ color: "#000000", fontSize: "14px" }}>
                  Email
                </label>
              </div>
              <div className="right-detail">{user.email}</div>
            </div>
            <div className="form-group">
              <div className="left-detail">
                <FontAwesomeIcon style={{ width: "14px" }} icon={faPhone} />
                <label style={{ color: "#000000", fontSize: "14px" }}>
                  Số điện thoại
                </label>
              </div>
              <div className="right-detail">{user.phone}</div>
            </div>
          </div>
        </div>
        <div className="payment-method">
          <ul>
            {" "}
            <li className="title">HÌNH THỨC THANH TOÁN</li>
          </ul>
          <div className="payment-detail">
            <span>
              {courseData.course_type === 1 ? "Có phí" : "Miễn phí"}- Nhận vé
              qua email
            </span>
          </div>
        </div>
        <div className="booking-detail">
          <ul>
            {" "}
            <li className="title">THÔNG TIN ĐẶT VÉ</li>{" "}
          </ul>
          <div className="form-group">
            <div className="left-detail">Loại vé</div>
            <div className="right-detail">Số lượng</div>
          </div>
          <div className="form-detail">
            <div className="left-detail">
              Vé mời tham gia (vé có giới hạn, vui lòng đăng ký sớm)
            </div>
            <div className="right-detail">{quantityRegis}</div>
          </div>
        </div>
        <div className="total">
          <label>Tổng cộng</label>
          <span>
            {formatCash((courseData.price * quantityRegis).toString())} đ
          </span>
        </div>
      </div>

      <div className="action-btn">
        <button
          onClick={async () => {
            if (searchParams.get("key") === null) {
              setSearchParams("key=step-question-form");
            }
            if (searchParams.get("key") === "step-question-form") {
              setSearchParams("key=payment");
            }
            if (searchParams.get("key") === "payment") {
              const data = {
                course_id: parseInt(courseData.id),
                type: 4,
                payment_method: paymentMethod,
                total_price: courseData.price * quantityRegis,
                list_details: listSubcribers.map((item) => {
                  return {
                    course_id: parseInt(courseData.id),
                    contact_name: item.contact_name,
                    address: item.address,
                    email: item.email,
                    phone: item.phone,
                  };
                }),
              };

              const res = await coursesApi.registerForMultiPeople(data);

              if (res.id !== undefined) {
                if (paymentMethod === 1 && res.id !== undefined) {
                  const dataCheckout = {
                    order: {
                      Id: res?.id.toString(),
                      amount: courseData.price * quantityRegis,
                      customerId: user.id.toString(),
                      type: 4,
                    },
                    transactionType: "domestic",
                  };
                  const resOnepay = await onePayApi.checkoutOnePay(
                    dataCheckout
                  );
                  if (resOnepay.message === "error") {
                    message.error(t("text.Payment_failed"));
                  } else {
                    window.location.href = resOnepay.url;
                  }
                }
              }
            }
          }}
        >
          {searchParams.get("key") === "payment" ? "Thanh toán" : "Tiếp tục"}
        </button>
      </div>
    </div>
  );
};

export default TicketRecipient;
