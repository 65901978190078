import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import "./CompanyProject.scss";
import parse from "html-react-parser";
import formatCash from "~/utils/formatCash";

const CompanyProject = ({ item }) => {
  return (
    <div className="company-project-item">
      <div className="image-group">
        <img
          src={
            item.image_url === undefined
              ? ""
              : `${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`
          }
          alt=""
        />
      </div>
      <div className="project-detail">
        <div className="top-detail">
          <Link className="title" to={`/project-detail/${item.id}`}>
            {item.name}
          </Link>

          {/* <Col span={2}>
                        <Link to={`/project-detail/${item.id}`}>Chi tiết</Link>
                    </Col> */}
        </div>
        <div className="bottom-detail">
          <div className="capital">
            <span style={{ color: "black" }}> Kêu gọi:</span>{" "}
            {formatCash(item?.amount_call.toString())} đ
          </div>
          <div className="location">
            <span style={{ color: "black" }}> Địa chỉ:</span>{" "}
            {item?.city_id?.name}, {item?.country_id?.name}
          </div>
        </div>
        <div className="description">{parse(`${item.des}`)}</div>
      </div>
    </div>
  );
};

export default CompanyProject;
