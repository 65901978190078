import { useEffect } from 'react';
import { Input, Table, Tabs } from 'antd';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderByStatus } from '~/store/Order/orderSlice';
import formatCash from '~/utils/formatCash';
import './AllOrder.scss';

const AllOrder = ({ isCancel = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listOrderByStatus } = useSelector((state) => state.orderReducer);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (isCancel) {
            dispatch(
                getOrderByStatus({
                    index: 1,
                    size: 5,
                    status: 6,
                }),
            );
        } else {
            if (searchParams.get('key') === null) {
                dispatch(
                    getOrderByStatus({
                        index: 1,
                        size: 5,
                        status: 1,
                    }),
                );
            } else {
                dispatch(
                    getOrderByStatus({
                        index: 1,
                        size: 5,
                        status: searchParams.get('key'),
                    }),
                );
            }
        }
    }, [searchParams]);

    const itemStatus = [
        {
            key: 1,
            label: t('text.Waiting_for_payment'),
        },
        {
            key: 2,
            label: t('text.Waiting_for_confirmation'),
        },
        {
            key: 3,
            label: t('text.Confirmed'),
        },
        {
            key: 4,
            label: t('text.Delivering'),
        },
        {
            key: 5,
            label: t('text.Delivered'),
        },
        {
            key: 6,
            label: t('text.Canceled'),
        },
    ];

    const getColumnSearchProps = (dataIndex) => ({
        render: (text, record, index) => {
            if (dataIndex === 'customer') {
                return record?.customer?.full_name;
            }
            if (dataIndex === 'payment_status') {
                if (record.payment_status === 1) {
                    return 'Đã thanh toán';
                } else {
                    return 'Chưa thanh toán';
                }
            }
            if (dataIndex === 'order_status') {
                let statusString = '';
                switch (record?.status) {
                    case 2:
                        statusString = 'Chờ thanh toán';
                        break;

                    case 3:
                        statusString = 'Chờ xác nhận';
                        break;
                    case 4:
                        statusString = 'Đang giao';
                        break;
                    case 5:
                        statusString = 'Đã giao';
                        break;
                    case 6:
                        statusString = 'Đã hủy';
                        break;
                    default:
                        break;
                }
                return statusString;
            }
            if (dataIndex === 'total_price') {
                return `${formatCash(record?.discount_detail?.total.toString())}đ`;
            }
        },
    });

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_date',
            key: 'created_date',
        },
        {
            title: 'Khách hàng',
            dataIndex: 'customer',
            key: 'customer',
            ...getColumnSearchProps('customer'),
        },
        {
            title: 'Thanh toán',
            dataIndex: 'payment_status',
            key: 'payment_status',
            ...getColumnSearchProps('payment_status'),
        },
        {
            title: 'Giao hàng',
            dataIndex: 'order_status',
            key: 'order_status',
            ...getColumnSearchProps('order_status'),
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total_price',
            key: 'total_price',
            ...getColumnSearchProps('total_price'),
        },
    ];

    const handleChangeOrderStatus = (value) => {
        setSearchParams({ key: value });
    };

    return (
        <div className="all-order-wrapper">
            <div className="inner">
                <Tabs
                    tabBarStyle={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    onChange={(value) => {
                        handleChangeOrderStatus(value);
                    }}
                    defaultActiveKey={
                        searchParams.get('key') === null && !isCancel
                            ? '1'
                            : searchParams.get('key') === null && isCancel
                            ? '6'
                            : searchParams.get('key')
                    }
                >
                    {itemStatus.map((item) => {
                        return <Tabs.TabPane tab={item.label} key={item.key}></Tabs.TabPane>;
                    })}
                </Tabs>
                <Table
                    columns={columns}
                    dataSource={listOrderByStatus?.data}
                    rowKey={(record) => record.id}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div className="order-desc-wrapper">
                                <div className="order-detail">
                                    <div className="product-details">
                                        {record.details.map((item) => {
                                            return (
                                                <div className="product-item">
                                                    <div className="image-group">
                                                        <img
                                                            src={
                                                                item?.product?.image_url === undefined
                                                                    ? ''
                                                                    : `${process.env.REACT_APP_API_URL}${item.product?.image_url[0]?.url}`
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product-item-detail">
                                                        <div className="product-name">
                                                            <span>{item?.product?.name}</span>
                                                            <p>x{item?.quantity}</p>
                                                        </div>
                                                        <div className="inventory-number">
                                                            <span>Còn khả dụng 234 trong kho Miền Bắc</span>
                                                            <p>{formatCash(item?.price.toString())} đ</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="address-detail">
                                        <div className="address-title">Thông tin giao hàng</div>
                                        <div className="customer-name">{record?.address?.name}</div>
                                        <div className="customer-info">{record?.address?.address_detail?.address}</div>
                                        <div className="customer-info">{record?.address?.phone}</div>
                                    </div>
                                    <div className="note-detail">
                                        <div className="note-title">Ghi chú</div>
                                        <Input.TextArea></Input.TextArea>
                                    </div>
                                </div>
                                <div className="order-action">
                                    <div className="left-action">
                                        <div className="action-btn">
                                            <FontAwesomeIcon icon={faPrint} /> In đơn hàng
                                        </div>
                                        <div className="action-btn">
                                            <FontAwesomeIcon icon={faCircleXmark} />
                                            Hủy đơn hàng
                                        </div>
                                    </div>
                                    <div className="right-action">
                                        <button>Xác thực đơn hàng</button>
                                    </div>
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default AllOrder;
