import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useCallback } from 'react';
import { isValid, validate } from '~/utils/validate';
import ToastPopup, { notifyError, notifySuccess } from '~/toast/toast';
import images from '~/assets/images/home/header';
import eventsApi from '~/api/eventsApi';
import './CreateEvents.scss';
import JoditEditor from 'jodit-react';

const CreateEvents = ({ mode }) => {
    const { t } = useTranslation();

    const params = useParams();
    const navigate = useNavigate();
    const [eventData, setEventData] = useState({
        name: '',
        content: '',
        cost: '',
        des: '',
        ecommerce: 69,
        image_url: [],
        start_time: '',
        end_time: '',
        address: '',
        type: '',
    });
    const TYPE = [
        {
            id: 1,
            title: t('text.WORKSHOPS'),
        },
        {
            id: 2,
            title: t('text.Competition'),
        },
        {
            id: 3,
            title: 'Hội chợ triển lãm',
        },
    ];

    const categoryItem = [
        {
            id: 723,
            title: 'Trong nước',
        },
        {
            id: 724,
            title: 'Nước ngoài',
        },
    ];

    const [, setFiles] = useState([]);

    let formData = new FormData();

    const getEventData = useCallback(async () => {
        const res = await eventsApi.get(params.id);
        setEventData(res);
    }, [params.id]);

    const formatDate = (date) => {
        return moment(date).format('yyyy-MM-DD');
    };
    const handleSelectChangeType = (selectOption) => {
        // eslint-disable-next-line no-lone-blocks
        {
            selectOption.value === 1
                ? setEventData({
                      ...eventData,
                      type: 4,
                  })
                : setEventData({
                      ...eventData,
                      type: 3,
                  });
        }
        // eslint-disable-next-line no-lone-blocks
        {
            selectOption.value === 2
                ? setEventData({
                      ...eventData,
                      type: 3,
                  })
                : setEventData({
                      ...eventData,
                      type: 4,
                  });
        }
        // eslint-disable-next-line no-lone-blocks
        {
            if (selectOption.value === 3) {
                setEventData({
                    ...eventData,
                    type: 5,
                });
            }
        }
    };
    const removeImage = (position) => {
        setEventData({
            ...eventData,
            image_url: eventData.image_url.filter((_, index) => position !== index),
        });
    };
    const handleFilesChange = async (e) => {
        setFiles(e.target.files);

        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setFiles([]);

            setEventData({
                ...eventData,
                image_url: eventData.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
            notifySuccess('Upload ảnh thành công');
        } catch (error) {
            notifyError('Upload ảnh không thành công');
        }
    };
    const isEmpty = (
        <div className="is-empty">
            <h3>{t('text.no_image')}</h3>
            <input
                type="file"
                multiple
                accept="img/*"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            />
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                {t('text.add_picture')}
            </label>
        </div>
    );
    const isNotEmpty = (arr) => {
        return (
            <div className="image-container">
                <div className="big-image">
                    <div className="delete-image" onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={`${process.env.REACT_APP_API_URL}/${arr[0].url}`} alt="big" />
                </div>
                <div className="small-image-container">
                    {arr.map((item, index) => {
                        if (index !== 0) {
                            return (
                                <div
                                    className="small-image"
                                    key={item.url}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                    }}
                                >
                                    <div className="delete-image" onClick={() => removeImage(index)}>
                                        X
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                    <div className="small-image upload">
                        <input
                            className="item__input"
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>Thêm ảnh</h4>
                            <img src={images.postImage} alt="upload" />
                        </label>
                    </div>
                </div>
            </div>
        );
    };
    const handleSave = async () => {
        try {
            const x = validate('event', eventData);
            if (!isValid('event', x)) {
                Object.values(x).forEach((error) => {
                    if (error) {
                        notifyError(error);
                    }
                });
            } else {
                await eventsApi.create({
                    ...eventData,
                    ecommerce_id: eventData.ecommerce,
                    description: eventData.des,
                    category_id: eventData.category_id.value,
                    image_url: eventData.image_url.map((item) => {
                        if (item.url) return item.url;
                        return item;
                    }),
                });
                notifySuccess('Thêm sự kiện thành công');
                setTimeout(() => {
                    navigate('/list-events');
                }, 1000);
            }
        } catch (error) {
            notifyError(error);
        }
    };

    console.log('eventdata', eventData);

    return (
        <div className="eventForm">
            <ToastPopup />
            {/* <h3 className="eventForm__title">{eventData.name}</h3> */}
            <div className="main-form">
                <div className="main-form__title">
                    <p>{t('text.general_information')}</p>
                </div>
                <div className="main-form__item w100">
                    <label htmlFor="" className="item__label">
                        {t('text.event_name')}
                        <span className="warning">*</span>
                    </label>
                    <input
                        type="text"
                        name=""
                        id=""
                        value={eventData.name}
                        onChange={(e) =>
                            setEventData({
                                ...eventData,
                                name: e.target.value,
                            })
                        }
                        className="item__input"
                    />
                </div>
                <div className="main-form__item w50">
                    <label htmlFor="" className="item__label">
                        {t('text.ticket_prices')}
                    </label>
                    <input
                        type="number"
                        name=""
                        id=""
                        min={0}
                        value={eventData.cost}
                        onChange={(e) => {
                            setEventData({
                                ...eventData,
                                cost: e.target.value,
                            });
                        }}
                        className="item__input"
                    />
                </div>
                <div className="main-form__item w50">
                    <label htmlFor="" className="item__label">
                        {t('register.form1.address')}
                    </label>
                    <input
                        type="text"
                        name=""
                        id=""
                        value={eventData.address}
                        onChange={(e) =>
                            setEventData({
                                ...eventData,
                                address: e.target.value,
                            })
                        }
                        className="item__input"
                    />
                </div>
                <div className="main-form__item w50">
                    <label htmlFor="" className="item__label">
                        {t('text.start_date')}
                    </label>
                    <input
                        type="date"
                        name=""
                        id=""
                        className="team__input"
                        value={formatDate(eventData.start_time)}
                        onChange={(e) =>
                            setEventData({
                                ...eventData,
                                start_time: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="main-form__item w50">
                    <label htmlFor="" className="item__label">
                        {t('text.end_date')}
                    </label>
                    <input
                        type="date"
                        name=""
                        id=""
                        className="item__input"
                        value={formatDate(eventData.end_time)}
                        onChange={(e) =>
                            setEventData({
                                ...eventData,
                                end_time: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="main-form__item w100">
                    <label htmlFor="" className="item__label">
                        Description
                    </label>
                    <input
                        type="text"
                        name=""
                        id=""
                        value={eventData.des}
                        onChange={(e) =>
                            setEventData({
                                ...eventData,
                                des: e.target.value,
                            })
                        }
                        className="item__input"
                    />
                </div>
                <div className="main-form__item w50">
                    <label htmlFor="" className="item__label">
                        {t('text.event_type')}
                    </label>
                    <Select
                        placeholder={t('text.select_event_type')}
                        options={TYPE.map((item, index) => {
                            return {
                                label: item.title,
                                value: item.id,
                            };
                        })}
                        onChange={handleSelectChangeType}
                    />
                </div>
                <div className="main-form__item w50">
                    <label htmlFor="" className="item__label">
                        Danh mục
                    </label>
                    <Select
                        value={eventData.category_id}
                        placeholder={t('text.select_event_type')}
                        options={categoryItem.map((item, index) => {
                            return {
                                label: item.title,
                                value: item.id,
                            };
                        })}
                        onChange={(e) => {
                            setEventData({
                                ...eventData,
                                category_id: e,
                            });
                        }}
                    />
                </div>
                <div className="main-form__item w100">
                    <label htmlFor="" className="item__label">
                        {t('text.content')}
                    </label>
                    {/* <input type="text" name="" id="" value={eventData.content} /> */}
                    <JoditEditor
                        name="content"
                        value={eventData.content}
                        onChange={(e) => {
                            setEventData({
                                ...eventData,
                                content: e,
                            });
                        }}
                    />
                </div>
            </div>

            <div className="main-form">
                <div className="main-form__title">
                    <p>{t('text.event_picture')}</p>
                </div>
                <div className="main-form_item w100">
                    {eventData.image_url === null || eventData.image_url?.length === 0
                        ? isEmpty
                        : isNotEmpty(eventData.image_url)}
                </div>
            </div>
            <div className="button-action-container">
                {/* {checkAddOrEditForm(params) && ( */}
                {/* <button className="button-action-delete">
                    <img src={images.CODImage} alt="icon" />
                    <p>Xóa</p>
                </button> */}
                {/* )} */}
                <button className="button-action-save" onClick={handleSave}>
                    <p>Lưu</p>
                </button>
            </div>
        </div>
    );
};

export default CreateEvents;
