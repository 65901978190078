import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import coursesApi from '~/api/CourseApi/coursesApi';
import formatCash from '~/utils/formatCash';
import styles from './FavoriteCourse.module.scss';

const cx = classNames.bind(styles);

const FavoriteCourse = () => {
    const navigate = useNavigate();
    const [statusLove, setStatusLove] = useState(false);
    const [dataFavoriteCourse, setDataFavoriteCourse] = useState();

    const classNotActive = cx('love-icon');
    const classesActive = cx('love-icon', 'active');

    useEffect(() => {
        const getFavoriteCourse = async () => {
            const res = await coursesApi.getFavoriteCourse();
            setDataFavoriteCourse(res);
        };
        getFavoriteCourse();
    }, []);

    const classesLoveIcon = statusLove ? classesActive : classNotActive;

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('header-main')}>
                    <div className={cx('header-title')}>
                        <span>Đơn hàng</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>Khóa học yêu thích</span>
                    </div>
                </div>
                <div className={cx('content-main')}>
                    <div className={cx('list-courses')}>
                        {dataFavoriteCourse?.map((item) => {
                            return (
                                <div key={item.id} className={cx('course-item')}>
                                    <div className={cx('course-image')}>
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${item?.course?.image_url[0].url}`}
                                            alt="Course"
                                        />
                                    </div>
                                    <div className={cx('course-details')}>
                                        <div className={cx('left-content')}>
                                            <span className={cx('course-name')}>{item.course.name}</span>
                                            <div className={cx('row-group')}>
                                                <label>Đối tượng:</label>
                                                <span>
                                                    {item?.course?.gender === 0
                                                        ? 'Nam'
                                                        : item?.course?.gender === 1
                                                        ? 'Nữ'
                                                        : 'Không giới hạn'}
                                                </span>
                                            </div>
                                            <div className={cx('row-group')}>
                                                <label>Thời gian học:</label>
                                                <span>Thứ 2 đến CN</span>
                                            </div>
                                            <div className={cx('row-group')}>
                                                <label>Số lượng:</label>
                                                <span>
                                                    {item?.course?.amount_min}-{item?.course?.amount_max} người
                                                </span>
                                            </div>
                                        </div>
                                        <div className={cx('right-content')}>
                                            <div
                                                className={classesLoveIcon}
                                                onClick={() => setStatusLove(!statusLove)}
                                            ></div>
                                            <div className={cx('actions-group')}>
                                                <span>
                                                    {item.course.course_type === 0
                                                        ? 'Miễn phí'
                                                        : `${formatCash(item?.course?.price?.toString())} VNĐ`}
                                                </span>
                                                <button
                                                    onClick={() => {
                                                        navigate(`/course-register/${item?.course?.id}`);
                                                    }}
                                                >
                                                    Tham gia
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FavoriteCourse;
