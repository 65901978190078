import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { getPurchasedCourse } from '~/store/Course/courseSlice';
import styles from './ParticipationCourse.module.scss';
import { dateCommon } from '~/utils/dateCommon';
import formatCash from '~/utils/formatCash';

const cx = classNames.bind(styles);

const ParticipationCourse = () => {
    const dispatch = useDispatch();
    const [statusLove, setStatusLove] = useState(false);

    const { listPurchasedCourse } = useSelector((state) => state.courseReducer);

    const classNotActive = cx('love-icon');
    const classesActive = cx('love-icon', 'active');

    useEffect(() => {
        dispatch(getPurchasedCourse());
    }, [dispatch]);

    const classesLoveIcon = statusLove ? classesActive : classNotActive;
    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('header-main')}>
                    <div className={cx('header-title')}>
                        <span>Đơn hàng</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>Khóa học đã mua</span>
                    </div>
                </div>
                <div className={cx('content-main')}>
                    <div className={cx('list-courses')}>
                        {listPurchasedCourse?.map((item) => {
                            return (
                                <div key={item.id} className={cx('course-item')}>
                                    <div className={cx('course-image')}>
                                        <img
                                            src={
                                                item.image_url === undefined
                                                    ? ''
                                                    : `${process.env.REACT_APP_API_URL}${item?.image_url[0].url}`
                                            }
                                            alt="Course"
                                        />
                                    </div>
                                    <div className={cx('course-details')}>
                                        <div className={cx('left-content')}>
                                            <span className={cx('course-name')}>{item.name}</span>
                                            <div className={cx('row-group')}>
                                                <label>Đối tượng:</label>
                                                <span>
                                                    {item.gender === 0
                                                        ? 'Nam'
                                                        : item.gender === 1
                                                        ? 'Nữ'
                                                        : 'Không giới hạn'}
                                                </span>
                                            </div>
                                            <div className={cx('row-group')}>
                                                <label>Thời gian học:</label>
                                                <span>
                                                    {dateCommon[item?.start_date]} đến {dateCommon[item?.end_date]}
                                                </span>
                                            </div>
                                            <div className={cx('row-group')}>
                                                <label>Số lượng:</label>
                                                <span>
                                                    {item?.amount_min} - {item?.amount_max} người
                                                </span>
                                            </div>
                                        </div>
                                        <div className={cx('right-content')}>
                                            <div
                                                className={classesLoveIcon}
                                                onClick={() => setStatusLove(!statusLove)}
                                            ></div>
                                            <div className={cx('actions-group')}>
                                                <span>
                                                    {item.course_type === 0
                                                        ? 'Miễn phí'
                                                        : `${formatCash(item.price.toString())} VNĐ`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipationCourse;
