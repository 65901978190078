import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { message } from 'antd';
import CartApi from '~/api/Cart/cartApi';
import voucherApi from '~/api/Voucher/VoucherApi';

export const addProductToCart = createAsyncThunk('cart/addToCart', async (data) => {
    await CartApi.addToCart(data);
});

export const getAllProductInCart = createAsyncThunk('cart/getAllCart', async () => {
    const listData = await CartApi.getAllCart();
    return listData;
});

export const updateQuantityCart = createAsyncThunk('cart/updateQuantity', async (data, thunkAPI) => {
    const newData = {
        quantity: data.quantity,
    };
    await CartApi.updateQuantity(newData, data.id_order_detail);
    return data;
});

export const deleteProductInCart = createAsyncThunk('cart/deleteCart', async (data, thunkAPI) => {
    await CartApi.deleteProductInCart(data);
    thunkAPI.dispatch(getAllProductInCart());
});

export const checkoutCart = createAsyncThunk('cart/checkout', async (data, thunkAPI) => {
    const resultData = await CartApi.checkoutCart(data);
    return resultData;
});

export const getShipFee = createAsyncThunk('cart/getShipFee', async (data, thunkAPI) => {
    const resultData = await CartApi.getShipFee(data);
    return resultData;
});

export const applyVoucher = createAsyncThunk('voucher/apply-voucher', async (data, thunkAPI) => {
    const resultData = await voucherApi.applyVoucherStore(data);
    if (resultData.message === 'error') {
        message.error('Lỗi không add được voucher');
    } else {
        message.success('Thêm voucher thành công');
    }
    return resultData;
});

export const applyVoucherEcommerce = createAsyncThunk('voucher/applyVoucherEcommerce', async (data, thunkAPI) => {
    const resultData = await voucherApi.applyVoucherEcommerce(data);
    if (resultData.message === 'error') {
        message.error('Lỗi không add được voucher');
    } else {
        message.success('Thêm voucher thành công');
    }
    return resultData;
});

export const saveDataCheck = createAsyncThunk('cart/saveDataCheck', async (data, thunkAPI) => {
    return data;
});

export const getSaveCheckedCart = createAsyncThunk('cart/saveCheckedCart', async () => {
    const data = await CartApi.getCheckedCart();
    return data;
});

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        loading: false,
        loadingUpdate: false,
        listProductsOfCart: [],
        dataCheckout: {},
        dataCheckedCart: [],
        loadingCountPrice: false,
    },
    reducers: {
        incrementByAmount(state, action) {
            state.dataCheckedCart = action.payload;
        },
    },
    extraReducers: {
        [getAllProductInCart.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllProductInCart.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAllProductInCart.fulfilled]: (state, action) => {
            state.loading = false;
            state.listProductsOfCart = action.payload;
        },
        [checkoutCart.pending]: (state, action) => {
            state.loading = true;
            state.loadingCountPrice = true;
        },
        [checkoutCart.rejected]: (state, action) => {
            state.loading = false;
            state.loadingCountPrice = false;
        },
        [checkoutCart.fulfilled]: (state, action) => {
            state.loading = false;
            state.loadingCountPrice = false;
            state.dataCheckout = action.payload;
        },
        [applyVoucher.pending]: (state, action) => {
            state.loading = true;
        },
        [applyVoucher.rejected]: (state, action) => {
            state.loading = false;
        },
        [applyVoucher.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCheckout = action.payload;
        },
        [applyVoucherEcommerce.pending]: (state, action) => {
            state.loading = true;
        },
        [applyVoucherEcommerce.rejected]: (state, action) => {
            state.loading = false;
        },
        [applyVoucherEcommerce.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCheckout = action.payload;
        },
        [getSaveCheckedCart.pending]: (state, action) => {
            state.loading = true;
        },
        [getSaveCheckedCart.rejected]: (state, action) => {
            state.loading = false;
        },
        [getSaveCheckedCart.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCheckedCart = action.payload;
        },
        [updateQuantityCart.pending]: (state, action) => {
            state.loadingUpdate = true;
        },
        [updateQuantityCart.rejected]: (state, action) => {
            state.loadingUpdate = false;
        },
        [updateQuantityCart.fulfilled]: (state, { payload }) => {
            state.loadingUpdate = false;
            state.listProductsOfCart = {
                ...current(state.listProductsOfCart),
                store: current(state.listProductsOfCart)?.store?.map((storeItem) => {
                    return {
                        ...storeItem,
                        products: storeItem.products.map((item) => {
                            if (item.id_order_detail === payload.id_order_detail) {
                                const tpItem = { ...item };
                                tpItem.quantity = payload?.quantity;
                                return tpItem;
                            } else return item;
                        }),
                    };
                }),
            };
        },
        [deleteProductInCart.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteProductInCart.rejected]: (state, action) => {
            state.loading = false;
        },
        [deleteProductInCart.fulfilled]: (state, action) => {
            state.loading = false;
        },
    },
});

export const { addToCart, incrementByAmount } = cartSlice.actions;
const { reducer: cartReducer } = cartSlice;
export default cartReducer;
