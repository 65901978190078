import './CardMember.scss';

const CardMember = ({ setShowModalReg }) => {
    return (
        <div className="card-member-item">
            <div className="image_group">
                <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStAPzCwCxaZsERPMQZTNvcsmfb-1hnTcdueQ&usqp=CAU"
                    alt=""
                />
            </div>
            <div className="member-detail">
                <div className="name">Nguyễn Thị Phương</div>
                <div className="position">Tư vấn viên</div>
                <div className="action-btn">
                    <button onClick={() => setShowModalReg(true)}>Liên hệ tư vấn</button>
                </div>
            </div>
        </div>
    );
};

export default CardMember;
