'use strict';

import { useEffect, useState } from 'react';
import { Skeleton, Spin, Tree } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import {
    deleteProductInCart,
    getAllProductInCart,
    incrementByAmount,
    updateQuantityCart,
} from '~/store/Cart/cartSlice';
import { getAllAddress } from '~/store/CustomerProfile/customerSlice';
import './MyCart.scss';

const MyCart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { listProductsOfCart, loadingUpdate, loading } = useSelector((state) => state.cartReducer);

    useEffect(() => {
        dispatch(getAllProductInCart());
        dispatch(getAllAddress());
    }, [dispatch]);

    const handleIncrease = (item) => {
        if (item.quantity > 1) {
            const data = {
                id_order_detail: item.id_order_detail,
                quantity: item.quantity - 1,
            };
            dispatch(updateQuantityCart(data));
        } else {
            const data = {
                id: [item.id_order_detail],
            };
            dispatch(deleteProductInCart(data));
        }
    };

    const handleCrease = (item) => {
        const data = {
            id_order_detail: item.id_order_detail,
            quantity: item.quantity + 1,
        };
        dispatch(updateQuantityCart(data));
    };

    const handleDelete = async (id) => {
        const arr = [id];

        const data = {
            id: arr,
        };

        dispatch(deleteProductInCart(data));
    };

    function formatCash(str) {
        return str
            .split('')
            .reverse()
            .reduce((prev, next, index) => {
                return (index % 3 ? next : next + ',') + prev;
            });
    }

    const tpArray2 = [
        {
            title: (
                <div className="my-cart-title-group">
                    <h2>Chọn tất cả</h2>
                    <button
                        onClick={() => {
                            navigate('/store-details');
                        }}
                    >
                        Mua thêm
                    </button>
                </div>
            ),
            key: 'all-store',
            children: listProductsOfCart?.store?.map((storeItem) => {
                return {
                    ...storeItem,
                    title: (
                        <div className="cart-details__header">
                            <span>{storeItem.name}</span>
                            <FontAwesomeIcon icon={faMessage} />
                        </div>
                    ),
                    key: storeItem.id,
                    children: storeItem?.products?.map((item) => {
                        return {
                            ...item,
                            title: (
                                <div key={item.id} className="commodity-details">
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}${item.image_url[0].url}`}
                                        alt={item.name}
                                    />
                                    <div className="commodity-right__group">
                                        <div className="info-commodity">
                                            <span className="commodity-name">{item.name}</span>
                                            <div className="color-size-group">
                                                <div className="color">
                                                    <label>Màu: </label>
                                                    {item.color}
                                                </div>
                                                <div className="size">
                                                    <label>Size: </label>
                                                    {item.size}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="commodity-price">
                                            <span className="current-price">
                                                {formatCash(item?.price.toString())} đ
                                            </span>
                                        </div>
                                        <div className="commodity-quantity">
                                            <div className="commodity-quantity__group">
                                                <button
                                                    disabled={loadingUpdate ? true : false}
                                                    onClick={() => handleIncrease(item)}
                                                >
                                                    <FontAwesomeIcon className="minus" icon={faMinus} />
                                                </button>
                                                <input
                                                    disabled={loadingUpdate ? true : false}
                                                    value={item.quantity}
                                                ></input>
                                                <button
                                                    disabled={loadingUpdate ? true : false}
                                                    onClick={() => handleCrease(item)}
                                                >
                                                    <FontAwesomeIcon className="plus" icon={faPlus} />
                                                </button>
                                            </div>
                                            <FontAwesomeIcon
                                                onClick={() => handleDelete(item.id_order_detail)}
                                                className="delete-commodity-btn"
                                                icon={faTrash}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: item.id,
                        };
                    }),
                };
            }),
        },
    ];

    const [checkedKeys, setCheckedKeys] = useState([11111]);

    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };

    const handleSubmitCheckout = async () => {
        dispatch(incrementByAmount(checkedKeys));
        localStorage.setItem('cart', JSON.stringify(checkedKeys));
        navigate('/payment-cart');
    };

    return (
        <div className="my-cart-wrapper">
            <Spin spinning={loading}>
                <div className="my-cart__header">
                    <h2>Giỏ hàng của tôi</h2>
                </div>
                <Skeleton loading={loading}>
                    <Tree
                        checkable
                        selectable={true}
                        allowDrop={false}
                        defaultExpandAll={true}
                        defaultExpandParent
                        selectedKeys={false}
                        autoExpandParent={true}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        treeData={tpArray2}
                    />
                </Skeleton>

                <div className="my-cart__right-wrapper">
                    <div className="order-action">
                        <button onClick={handleSubmitCheckout}>Tiến hành đặt hàng</button>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default MyCart;
