import axiosClient from '../axiosClient';

const OrderApi = {
    async getOrderByStatus(data) {
        const url = `/orders/status?page_index=${data.index}&page_size=${data.size}&status=${data.status}`;
        return axiosClient.get(url);
    },
};

export default OrderApi;
