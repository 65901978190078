import { useParams } from "react-router-dom";
import "./OverviewProject.scss";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProjectById } from "~/store/Projects/projectsSlice";
const OverviewProject = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectById } = useSelector((state) => state.projectReducer);

  useEffect(() => {
    dispatch(getProjectById(id));
  }, [dispatch, id]);
  return (
    <div className="overview-project-wrap">
      <div className="row-group">
        <label>Tên dự án: </label>
        <span>{projectById?.name}</span>
      </div>
      <div className="row-group">
        <label>Ngày thành lập: </label>
        <span>{projectById?.established_date}</span>
      </div>

      <div className="row-group">
        <label>Địa điểm: </label>
        <span>{projectById?.city_id?.name}</span>,{" "}
        <span>{projectById?.country_id?.name}</span>
      </div>
      {/* <div className="row-group">
        <label>City/Province: </label>
      </div> */}
      <div className="row-group">
        <label>Website: </label>
        <span>{projectById?.website}</span>
      </div>
      <div className="row-group">
        <label>Nội dung: </label>
        <span>{parse(`${projectById?.des}`)}</span>
      </div>
    </div>
  );
};

export default OverviewProject;
