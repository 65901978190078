import formatCash from "~/utils/formatCash";
import "./CardProject.scss";
import { useNavigate } from "react-router-dom";

const CardProject = ({ item }) => {
  console.log("item", item);
  const navigate = useNavigate();
  return (
    <div className="card-project-item">
      <div className="image-group">
        <img
          src={`${process.env.REACT_APP_API_URL}${item?.image_url[0].url}`}
          alt=""
        />
      </div>
      <div className="card-project-detail">
        <div
          className="name"
          onClick={() => {
            navigate(`/project-detail/${item.id}`);
            window.scrollTo(0, 0);
          }}
        >
          {item?.name}
        </div>
        <span className="description">{item?.intro}</span>
        <span className="investment-call">
          Kêu gọi: {formatCash(item?.amount_call?.toString())}đ
        </span>
        <div className="location">
          {item?.city_id?.name}, {item?.country_id?.name}
        </div>
      </div>
    </div>
  );
};

export default CardProject;
