import classNames from 'classnames/bind';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, Form, Input, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

import { getCourseById } from '~/store/Course/courseSlice';
import coursesApi from '~/api/CourseApi/coursesApi';
import emptyIcon from '~/assets/images/Products/empty.svg';
import uploadIcon from '~/assets/images/Products/upload.svg';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import styles from './CreateCourse.scss';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

const { Option } = Select;

const cx = classNames.bind(styles);

const CreateCourse = ({ isEdit = false }) => {
    const { RangePicker } = DatePicker;
    const { t } = useTranslation();
    const [formAdd] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { courseById } = useSelector((state) => state.courseReducer);

    const [dataCourse, setDataCourse] = useState({
        name: '',
        type: 2,
        gender: '',
        introduce: '',
        start_age: '',
        end_age: '',
        start_date: '',
        end_date: '',
        amount_min: '',
        amount_max: '',
        address: '',
        price: 0,
        image_url: [],
        course_type: 0,
        content: '',
        begin_date: courseById?.begin_date?.substring(0, 10),
        over_date: courseById?.over_date?.substring(0, 10),
    });

    useEffect(() => {
        if (isEdit) {
            dispatch(getCourseById(id));
        }
    }, [dispatch, id, isEdit]);

    useEffect(() => {
        if (isEdit) {
            const dataEdit = {
                name: courseById.name,
                type: 2,
                gender: parseInt(courseById.gender),
                introduce: courseById.introduce,
                start_age: courseById.start_age,
                end_age: parseInt(courseById.end_age),
                start_date: parseInt(courseById.start_date),
                end_date: parseInt(courseById.end_date),
                amount_min: courseById.amount_min,
                amount_max: courseById.amount_max,
                address: courseById.address,
                price: parseInt(courseById.price),
                image_url: dataCourse.image_url,
                course_type: dataCourse.course_type,
                content: courseById.content,
                begin_date: dataCourse.begin_date,
                over_date: dataCourse.over_date,
            };

            setDataCourse({
                ...dataCourse,
                begin_date: courseById?.begin_date?.substring(0, 10),
                over_date: courseById?.over_date?.substring(0, 10),
                image_url: courseById.image_url,
            });

            formAdd.setFieldsValue(dataEdit);
        }
    }, [courseById]);

    const formData = new FormData();

    const onChangeDataCourse = (e) => {
        setDataCourse((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleChangeDate = () => {
        if (courseById.begin_date !== undefined || courseById.over_date !== undefined) {
            return courseById?.begin_date?.substring(0, 10);
        } else {
            return '';
        }
    };

    const handleFilesChange = async (e) => {
        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setDataCourse({
                ...dataCourse,
                image_url: dataCourse.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
        } catch (error) {
            console.log(error);
        }
    };

    const removeImage = (position) => {
        setDataCourse({
            ...dataCourse,
            image_url: dataCourse.image_url.filter((_, index) => position !== index),
        });
    };

    const isEmpty = (
        <div className="is-empty">
            <h3>Hiện tại chưa có ảnh</h3>
            <img src={emptyIcon} alt="empty" />
            <input
                type="file"
                multiple
                accept="img/*"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            />
            <label style={{ cursor: 'pointer' }} htmlFor="file-upload">
                Thêm ảnh
            </label>
        </div>
    );

    const isNotEmpty = (arr) => {
        return (
            <div className="image-container">
                <div className="big-image">
                    <div className="delete-image" onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={arr === undefined ? '' : `${process.env.REACT_APP_API_URL}/${arr[0].url}`} alt="big" />
                </div>
                <div className="small-image-container">
                    {arr === undefined ? (
                        <></>
                    ) : (
                        arr.map((item, index) => {
                            if (index !== 0) {
                                return (
                                    <div
                                        className="small-image"
                                        key={item.url}
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                        }}
                                    >
                                        <div className="delete-image" onClick={() => removeImage(index)}>
                                            X
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })
                    )}
                    <div className="small-image upload">
                        <input
                            className="item__input"
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>Thêm ảnh</h4>
                            <img src={uploadIcon} alt="upload" />
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const handleSubmit = async (value) => {
        if (isEdit) {
            const dataEdit = {
                name: value.name,
                type: 2,
                gender: parseInt(value.gender),
                introduce: value.introduce,
                start_age: parseInt(value.start_age),
                end_age: parseInt(value.end_age),
                start_date: parseInt(value.start_date),
                end_date: parseInt(value.end_date),
                amount_min: parseInt(value.amount_min),
                amount_max: parseInt(value.amount_max),
                address: value.address,
                image_url: dataCourse.image_url.map((item) => item.url),
                price: parseInt(value.price),
                course_type: 0,
                content: value.content,
                begin_date: dataCourse.begin_date,
                over_date: dataCourse.over_date,
            };

            const res = await coursesApi.editCourse({
                id: id,
                body: dataEdit,
            });

            if (res.status === 1) {
                message.success('Chỉnh sửa thành công');
            } else {
                message.error('Chỉnh sửa thất bại');
            }
        } else {
            const dataAdd = {
                name: value.name,
                type: 2,
                gender: parseInt(value.gender),
                introduce: value.introduce,
                start_age: parseInt(value.start_age),
                end_age: parseInt(value.end_age),
                start_date: parseInt(value.start_date),
                end_date: parseInt(value.end_date),
                amount_min: parseInt(value.amount_min),
                amount_max: parseInt(value.amount_max),
                address: value.address,
                price: parseInt(value.price),
                image_url: dataCourse.image_url.map((item) => item.url),
                course_type: 0,
                content: value.content,
                begin_date: dataCourse.begin_date,
                over_date: dataCourse.over_date,
            };
            const res = await coursesApi.createCourse(dataAdd);
            if (res.id !== undefined) {
                message.success('Tạo khóa học thành công');
                formAdd.resetFields();
            } else {
                message.error('Tạo khóa học không thành công');
            }
        }
    };

    return (
        <div className="create-course-wrapper">
            <div className={cx('inner')}>
                <div className={cx('header-main')}>
                    <div className={cx('header-title')}>
                        <span>{t('text.Courses')}</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>{t('text.add_courses')}</span>
                    </div>
                </div>
                <div className={cx('content-main')}>
                    <h3>{t('text.basic_information')}</h3>
                    <div className={cx('create-course-form')}>
                        <Form name="form-create-course" onFinish={handleSubmit} form={formAdd}>
                            <div className={cx('form-wrapper')}>
                                <div className={cx('form-top')}>
                                    <div className={cx('form-top-left')}>
                                        <div className={cx('form-top-left-group')}>
                                            <label htmlFor="name">{t('text.Course_name')}</label>
                                            <Form.Item
                                                className={cx('form-group')}
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Tên khóa học không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className={cx('input-7')}
                                                    placeholder={t('text.enter_name')}
                                                    onChange={onChangeDataCourse}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={cx('form-top-left-group')}>
                                            <label htmlFor="address">{t('register.form1.address')}</label>
                                            <Form.Item
                                                className={cx('form-group')}
                                                name="address"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Địa chỉ không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className={cx('input-7')}
                                                    onChange={onChangeDataCourse}
                                                    placeholder={t('login.forgetpassword.detail')}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={cx('group-left')}>
                                            <div className={cx('start-date')}>
                                                <div className={cx('form-top-left-group')}>
                                                    <label htmlFor="start-date">{t('text.Study_from')}</label>
                                                    <Form.Item
                                                        className={cx('form-group')}
                                                        name="start_date"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Ngày học không được để trống',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className={cx('select-group')}
                                                            placeholder={t('text.Study_from')}
                                                            onChange={(value) => {
                                                                setDataCourse({
                                                                    ...dataCourse,
                                                                    start_date: value,
                                                                });
                                                            }}
                                                        >
                                                            <Option value={1}>Thứ Hai</Option>
                                                            <Option value={2}>Thứ Ba</Option>
                                                            <Option value={3}>Thứ Tư</Option>
                                                            <Option value={4}>Thứ Năm</Option>
                                                            <Option value={5}>Thứ Sáu</Option>
                                                            <Option value={6}>Thứ Bảy</Option>
                                                            <Option value={7}>Chủ Nhật</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className={cx('end-date')}>
                                                <div className={cx('form-top-left-group')}>
                                                    <label htmlFor="end-date">{t('text.to')}</label>
                                                    <Form.Item
                                                        className={cx('form-group')}
                                                        name="end_date"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Ngày học không được để trống',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className={cx('select-group')}
                                                            placeholder={t('text.to')}
                                                            onChange={(value) => {
                                                                setDataCourse({
                                                                    ...dataCourse,
                                                                    end_date: value,
                                                                });
                                                            }}
                                                        >
                                                            <Option value={1}>Thứ Hai</Option>
                                                            <Option value={2}>Thứ Ba</Option>
                                                            <Option value={3}>Thứ Tư</Option>
                                                            <Option value={4}>Thứ Năm</Option>
                                                            <Option value={5}>Thứ Sáu</Option>
                                                            <Option value={6}>Thứ Bảy</Option>
                                                            <Option value={7}>Chủ Nhật</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('group-left')}>
                                            <div className={cx('start-date')}>
                                                <div className={cx('form-top-left-group')}>
                                                    <label htmlFor="start-age">{t('text.Start_Age')}</label>
                                                    <Form.Item
                                                        className={cx('form-group')}
                                                        name="start_age"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Tuổi bắt đầu không được để trống',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className={cx('input-7')}
                                                            placeholder={t('text.Start_Age')}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className={cx('end-date')}>
                                                <div className={cx('form-top-left-group')}>
                                                    <label htmlFor="end-age">{t('text.End Age')}</label>
                                                    <Form.Item
                                                        className={cx('form-group')}
                                                        name="end_age"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Tuổi kết thúc không được để trống',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className={cx('input-7')}
                                                            id="end_age"
                                                            name="end_age"
                                                            onChange={onChangeDataCourse}
                                                            placeholder={t('text.End Age')}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('form-top-right')}>
                                        <div className={cx('form-top-right-group')}>
                                            <label htmlFor="amount">{t('text.Start_End_date')}</label>

                                            <Form.Item
                                                name="start_end_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ngày bắt đầu/kết thúc không được để trống',
                                                    },
                                                ]}
                                            >
                                                <RangePicker
                                                    className={cx('range-picker')}
                                                    // defaultValue={[dayjs('', 'YYYY-MM-DD'), dayjs('', 'YYYY-MM-DD')]}
                                                    format="YYYY-MM-DD"
                                                    disabledDate={(currentDate) => {
                                                        return currentDate && currentDate < moment().startOf('day');
                                                        //không được chọn ngày trước ngày hôm nay
                                                    }}
                                                    onChange={(date, dateString) => {
                                                        setDataCourse({
                                                            ...dataCourse,
                                                            begin_date: dateString[0],
                                                            over_date: dateString[1],
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className={cx('form-top-right-group')}>
                                            <label htmlFor="website-input">{t('register.form1.gender')}</label>
                                            <Form.Item
                                                className={cx('form-group')}
                                                name="gender"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Giới tính không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className={cx('select-group')}
                                                    placeholder={t('register.form1.male/female')}
                                                    onChange={(value) => {
                                                        setDataCourse({
                                                            ...dataCourse,
                                                            gender: value,
                                                        });
                                                    }}
                                                >
                                                    <Option value={0}>{t('register.form1.male')}</Option>
                                                    <Option value={1}>{t('register.form1.female')}</Option>
                                                    <Option value={2}>{t('text.other')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className={cx('form-top-right-group')}>
                                            <label htmlFor="website-input">{t('text.Course_type')}</label>
                                            <Form.Item
                                                className={cx('form-group')}
                                                name="course_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Loại khóa học không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className={cx('select-group')}
                                                    placeholder={t('text.Course_type')}
                                                    onChange={(value) => {
                                                        setDataCourse({
                                                            ...dataCourse,
                                                            course_type: parseInt(value),
                                                        });
                                                    }}
                                                >
                                                    <Option value={0}>Miễn phí</Option>
                                                    <Option value={1}>Có phí</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className={cx('form-top-right-group')}>
                                            <label htmlFor="website-input">{t('text.Price_if')} </label>
                                            <Form.Item className={cx('form-group')} name="price">
                                                <Input
                                                    className={cx('input-3')}
                                                    onChange={onChangeDataCourse}
                                                    placeholder={t('text.enter_price')}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('participate-group')}>
                                    <div className={cx('participate')}>
                                        <label htmlFor="amount_min">{t('text.Number_of_participants_from')}</label>
                                        <Form.Item
                                            className={cx('form-group')}
                                            name="amount_min"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Số người tham gia không được để trống',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={cx('input-3')}
                                                placeholder={t('text.Number_of_participants_from')}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={cx('participate')}>
                                        <label htmlFor="amount_max">{t('text.to')}</label>
                                        <Form.Item
                                            className={cx('form-group')}
                                            name="amount_max"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Số người tham gia không được để trống',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={cx('input-3')}
                                                placeholder={t('text.enter_number_of_participants')}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={cx('form-bottom')}>
                                    <div className={cx('form-bottom-group')}>
                                        <label htmlFor={cx('introduce')}>{t('text.description')}</label>
                                        <Form.Item
                                            className={cx('form-group')}
                                            name="introduce"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Mô tả không được để trống',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                className={cx('input-textarea')}
                                                onChange={onChangeDataCourse}
                                                placeholder={t('text.enter_description')}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={cx('content')}>
                                        <label htmlFor="">{t('text.Course_content')}</label>
                                        <Form.Item
                                            name="content"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Nội dung khóa học không được để trống',
                                                },
                                            ]}
                                        >
                                            <JoditEditor
                                                className="content"
                                                name="content"
                                                value={dataCourse.content}
                                                onChange={(value) => setDataCourse({ ...dataCourse, content: value })}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <Form.Item
                                            name="image_url"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Ảnh khóa học không được để trống',
                                                },
                                            ]}
                                        >
                                            <div className="product-image__content">
                                                {dataCourse.image_url === null || dataCourse.image_url?.length === 0
                                                    ? isEmpty
                                                    : isNotEmpty(dataCourse.image_url)}
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className={cx('action-submit')}>
                                        <button>{!isEdit ? t('text.add_courses') : 'Lưu thay đổi'}</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCourse;
