import { message } from "antd";
import { projectsApi } from "~/api/Projects/projectsApi";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllCountry = createAsyncThunk(
  "project/getAllCountry",
  async () => {
    const res = await projectsApi.getAllCountry();
    return res;
  }
);

export const getAllProject = createAsyncThunk(
  "project/getAllProject",
  async () => {
    const res = await projectsApi.getAllProject();
    return res;
  }
);

export const getProjectByStore = createAsyncThunk(
  "project/getProjectByStore",
  async (data) => {
    const res = await projectsApi.getProjectByStore(
      data.id,
      data.index,
      data.size
    );
    return res;
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (id, thunkAPI) => {
    const res = await projectsApi.delete(id);
    thunkAPI.dispatch(getAllProject());
    return res;
  }
);

export const getProjectById = createAsyncThunk(
  "project/getProjectById",
  async (id) => {
    const res = await projectsApi.getProjectById(id);
    return res;
  }
);

export const getListContact = createAsyncThunk(
  "project/getListContact",
  async (id) => {
    const res = await projectsApi.getListContact(id);
    return res;
  }
);

export const getProjectAllPaging = createAsyncThunk(
  "project/getProjectAllPaging",
  async (data) => {
    const res = await projectsApi.getProjectAllPaging(data);
    return res;
  }
);

export const editProjectContributor = createAsyncThunk(
  "project/editProjectContributor",
  async (data, thunkAPI) => {
    const res = await projectsApi.updateContributor(data);
    if (res.status === 200) {
      message.success("Cập nhật thành công");
      thunkAPI.dispatch(getListContact(data?.project_id));
    } else {
      message.error("Có lỗi xảy ra");
    }
  }
);

export const editProject = createAsyncThunk(
  "project/editProject",
  async (data, thunkAPI) => {
    const res = await projectsApi.edit(data);
    return res;
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState: {
    loading: false,
    listCountry: [],
    listProject: [],
    projectById: {},
    listContact: [],
    listProjectAllPaging: [],
  },
  reducers: {},
  extraReducers: {
    [getAllCountry.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllCountry.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllCountry.fulfilled]: (state, action) => {
      state.loading = false;
      state.listCountry = action.payload;
    },
    [getAllProject.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllProject.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.listProject = action.payload;
    },

    [getProjectByStore.pending]: (state, action) => {
      state.loading = true;
    },
    [getProjectByStore.rejected]: (state, action) => {
      state.loading = false;
    },
    [getProjectByStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.listProject = action.payload;
    },

    [getProjectById.pending]: (state, action) => {
      state.loading = true;
    },
    [getProjectById.rejected]: (state, action) => {
      state.loading = false;
    },
    [getProjectById.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectById = action.payload;
    },
    [getListContact.pending]: (state, action) => {
      state.loading = true;
    },
    [getListContact.rejected]: (state, action) => {
      state.loading = false;
    },
    [getListContact.fulfilled]: (state, action) => {
      state.loading = false;
      state.listContact = action.payload;
    },
    [getProjectAllPaging.pending]: (state, action) => {
      state.loading = true;
    },
    [getProjectAllPaging.rejected]: (state, action) => {
      state.loading = false;
    },
    [getProjectAllPaging.fulfilled]: (state, action) => {
      state.loading = false;
      state.listProjectAllPaging = action.payload;
    },
  },
});

const { reducer: projectReducer } = projectSlice;

export default projectReducer;
