import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import "./ShoppingMall.scss";

const ShoppingMall = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className="shopping-mall-item">
      <div className="image-group">
        <img
          src={
            item.image_url === undefined
              ? "https://tradepro.vn/images/2022/banner_vietnam-ete-2022.jpg"
              : `${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`
          }
          alt=""
        />
      </div>
      <div className="shopping-mall-detail">
        <h3 style={{ marginBottom: "16px" }}>{item?.name}</h3>

        <div>
          <label>Thời gian: </label>
          <span>
            Từ {item?.start_time?.substring(0, 10)} đến{" "}
            {item?.end_time?.substring(0, 10)}
          </span>
        </div>
        <div>
          <label>Quy mô dự kiến: </label>
          <span>400 gian hàng</span>
        </div>
        <div className="row-group">
          <label>Địa điểm: </label>
          <span>{item?.address}</span>
        </div>
        <div className="row-group dess">
          Ngành hàng trưng bày: <span>{item?.des}</span>
        </div>
        <div className="action-btn">
          <button onClick={() => navigate(`/fair/${item?.id}`)}>
            Xem thêm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShoppingMall;
