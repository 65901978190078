import axios from 'axios';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ToastPopup, { notifyError, notifySuccess } from '~/toast/toast';
import enterpriseApi from '~/api/Enterprise/EnterpriseApi';
import { getAllCities } from '~/store/CustomerProfile/customerSlice';
import { getAllField } from '~/store/Store/storeSlice';
import styles from './CreateConsultant.module.scss';
import { getAllEnterprise, getAllEnterpriseById } from '~/store/Enterprise/enterpriseSlice';

const cx = classNames.bind(styles);
const { Option } = Select;

const CreateConsultant = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    let formData = new FormData();
    const [formEdit] = Form.useForm();
    const [, setFiles] = useState([]);
    const dispatch = useDispatch();
    const { allCities } = useSelector((state) => state.customerReducer);
    const { listField } = useSelector((state) => state.storesReducer);
    const { enterpriseListById } = useSelector((state) => state.enterpriseReducer);
    const [enterpriseData, setEnterpriseData] = useState({
        name: '',
        phone: '',
        address: '',
        tax_code: '',
        address_register: '',
        image_url: [],
        city_id: '',
        field_id: '',
    });

    useEffect(() => {
        if (isEdit) {
            dispatch(getAllEnterpriseById(id));
        } else {
            dispatch(getAllEnterprise());
        }
        dispatch(getAllField());
        dispatch(getAllCities());
    }, [dispatch, isEdit, id]);

    useEffect(() => {
        if (isEdit) {
            const dataEdit = {
                name: enterpriseListById.name,
                phone: enterpriseListById.phone,
                address: enterpriseListById.address,
                tax_code: enterpriseListById.tax_code,
                city_id: enterpriseListById?.city_id?.id,
                field_id: enterpriseListById?.field_id?.id,
            };

            setEnterpriseData({
                ...enterpriseData,
                image_url: enterpriseListById.image_url,
            });

            formEdit.setFieldsValue(dataEdit);
        }
    }, [enterpriseListById]);

    const removeImage = (position) => {
        setEnterpriseData({
            ...enterpriseData,
            image_url: enterpriseData.image_url.filter((_, index) => position !== index),
        });
    };
    const handleFilesChange = async (e) => {
        setFiles(e.target.files);

        for (const key of Object.keys(e.target.files)) {
            formData.append('files', e.target.files[key]);
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload/upload-array`, formData);
            setFiles([]);

            setEnterpriseData({
                ...enterpriseData,
                image_url: enterpriseData.image_url.concat(
                    res.data.url.map((image) => {
                        return {
                            url: image,
                        };
                    }),
                ),
            });
            notifySuccess('Upload ảnh thành công');
        } catch (error) {
            notifyError('Upload ảnh không thành công');
        }
    };

    const isEmpty = (
        <div className={cx('is-empty')}>
            <h3>Hiện tại chưa có ảnh</h3>
            <input
                type="file"
                multiple
                accept="img/*"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            />
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                Thêm ảnh
            </label>
        </div>
    );
    const isNotEmpty = (arr) => {
        return (
            <div className={cx('image-container')}>
                <div className={cx('big-image')}>
                    <div className={cx('delete-image')} onClick={() => removeImage(0)}>
                        X
                    </div>
                    <img src={arr === undefined ? '' : `${process.env.REACT_APP_API_URL}/${arr[0]?.url}`} alt="big" />
                </div>
                <div className={cx('small-image-container')}>
                    {arr === undefined ? (
                        <></>
                    ) : (
                        arr.map((item, index) => {
                            if (index !== 0) {
                                return (
                                    <div
                                        className={cx('small-image')}
                                        key={item.url}
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                                        }}
                                    >
                                        <div className={cx('delete-image')} onClick={() => removeImage(index)}>
                                            X
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })
                    )}
                    <div className={cx('small-image upload')}>
                        <input
                            className={cx('item__input')}
                            type="file"
                            id="file-upload"
                            multiple
                            accept="image/*"
                            onChange={handleFilesChange}
                        />
                        <label htmlFor="file-upload">
                            <h4>Thêm ảnh</h4>
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const handleSave = async (value) => {
        if (isEdit) {
            const dataEdit = {
                name: value.name,
                phone: value.phone,
                address: value.address,
                city_id: value.city_id,
                tax_code: value.tax_code,
                field_id: value.field_id,
                image_url: enterpriseData.image_url.map((item) => {
                    if (item.url) return item.url;
                    return item;
                }),
            };

            const res = await enterpriseApi.edit({
                id: id,
                body: dataEdit,
            });

            if (res.status === 200) {
                notifySuccess('Thêm nhà tư vấn thành công');
            } else {
                notifyError('Có lỗi xảy ra');
            }
        } else {
            try {
                const dataAdd = {
                    name: value.name,
                    phone: value.phone,
                    address: value.address,
                    city_id: value.city_id,
                    tax_code: value.tax_code,
                    field_id: value.field_id,
                    image_url: enterpriseData.image_url.map((item) => {
                        if (item.url) return item.url;
                        return item;
                    }),
                };
                await enterpriseApi.create(dataAdd);
                notifySuccess('Thêm nhà tư vấn thành công');
            } catch (error) {
                notifyError(error);
            }
        }
    };
    return (
        <div className={cx('wrapper')}>
            <ToastPopup />
            <div className={cx('inner')}>
                <div className={cx('header-main')}>
                    <div className={cx('header-title')}>
                        <span>{t('text.consultant')}</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>{t('text.create_consultant')}</span>
                    </div>
                </div>
                <div className={cx('main-content')}>
                    <div className={cx('form-wrapper')}>
                        <div className={cx('upload-avatar')}></div>
                        <Form className={cx('form-inner')} onFinish={handleSave} layout="vertical" form={formEdit}>
                            <div className={cx('bottom-form')}>
                                <div className={cx('form-left')}>
                                    <div className={cx('form-left-group')}>
                                        <Form.Item
                                            name="name"
                                            label="Tên nhà tư vấn"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tên nhà tư vấn không được để trống',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('text.enter_name')}
                                                onChange={(e) =>
                                                    setEnterpriseData({
                                                        ...enterpriseData,
                                                        name: e.target.value,
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={cx('form-left-group')}>
                                        <Form.Item
                                            className={cx('form-group')}
                                            label="Số điện thoại"
                                            name="phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Số điện thoại không được để trống',
                                                },
                                                {
                                                    pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
                                                    message: 'Số điện thoại không đúng định dạng',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('text.enter_phone_number')}
                                                onChange={(e) =>
                                                    setEnterpriseData({
                                                        ...enterpriseData,
                                                        phone: e.target.value,
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={cx('form-left-group')}>
                                        <Form.Item
                                            className={cx('form-group')}
                                            label="Nhập địa chỉ"
                                            name="address"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Địa chỉ không được để trống',
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t('text.enter_address')} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={cx('form-right')}>
                                    <div className={cx('form-right-group')}>
                                        <Form.Item
                                            name="tax_code"
                                            style={{
                                                width: '48%',
                                            }}
                                            label="Mã số thuế"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Mã số thuế không được để trống',
                                                },
                                            ]}
                                        >
                                            <Input id="tax-input" placeholder={t('text.enter_tax_code')} />
                                        </Form.Item>
                                    </div>

                                    <div className={cx('choose-city-fields')}>
                                        <div className={cx('city')}>
                                            <Form.Item
                                                name="city_id"
                                                label="Thành phố"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Mã số thuế không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Select value={allCities.store} placeholder="Chọn thành phố">
                                                    {allCities.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className={cx('fields')}>
                                            <Form.Item
                                                label="Lĩnh vực"
                                                name="field_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Mã số thuế không được để trống',
                                                    },
                                                ]}
                                            >
                                                <Select value={listField.store} placeholder="Chọn lĩnh vực">
                                                    {listField.map((item, index) => {
                                                        return <Option value={item.id}>{item.name}</Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={cx('main-form_item', 'w100')}>
                                        {enterpriseData.image_url === null || enterpriseData.image_url?.length === 0
                                            ? isEmpty
                                            : isNotEmpty(enterpriseData.image_url)}
                                    </div>
                                </div>
                            </div>
                            <div className={cx('action-submit')}>
                                <button className={cx('btn-submit')} type="submit">
                                    {t('text.create_consultant')}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateConsultant;
