import { message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAllEventsWithPag } from "~/store/Categories/eventSlice";
import RegCompetition from "./RegCompetition";
import "./Participate.scss";

const Participate = () => {
  const [showModalRegCompetition, setShowModalRegCompetition] = useState();
  const [idCompetition, setIdCompetition] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { eventListPag } = useSelector((state) => state.eventsReducer);
  console.log("data", eventListPag);

  useEffect(() => {
    dispatch(
      getAllEventsWithPag({
        type: 3,
        page_index: 1,
        page_size: 5,
      })
    );
  }, []);

  let newDate = new Date();
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  return (
    <div className="competition-wrapper" id="competition-activity">
      <div className="list-competition">
        {eventListPag.map((item) => {
          return (
            <div key={item.id} className="competition-item">
              <div className="image-group">
                <img
                  src={`${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`}
                  alt=""
                />
              </div>
              <div className="abc">
                <div className="competition-details">
                  <h3>{item.name}</h3>

                  <span className="date-time">
                    Thời gian:{" "}
                    <span>
                      {formatDate(item.start_time)} -{" "}
                      {formatDate(item.end_time)}
                    </span>
                  </span>
                  <div className="program">
                    {t("text.Programmes")}: <span>{item.des}</span>
                  </div>
                  <div className="status">
                    {newDate > Date.parse(item.start_time) &&
                    newDate < Date.parse(item.end_time) ? (
                      <button className="status-btn in-progress">
                        {" "}
                        {t("text.On_going")}
                      </button>
                    ) : newDate < Date.parse(item.start_time) ? (
                      <button
                        className="status-btn upcoming"
                        onClick={() => {
                          setShowModalRegCompetition(true);
                          setIdCompetition(item);
                        }}
                      >
                        {" "}
                        {t("text.Register")}
                      </button>
                    ) : (
                      <button className="status-btn end">
                        {" "}
                        {t("text.Ended")}
                      </button>
                    )}
                  </div>
                </div>
                {/* <div className="action-submit">
                  {(newDate > Date.parse(item.start_time) &&
                    newDate < Date.parse(item.end_time)) ||
                  newDate > Date.parse(item.end_time) ? (
                    <></>
                  ) : (
                    // <button className="btnEnd">Đã hết thời gian</button>
                    <button
                      onClick={() => {
                        if (
                          (newDate > Date.parse(item.start_time) &&
                            newDate < Date.parse(item.end_time)) ||
                          newDate > Date.parse(item.end_time)
                        ) {
                          message.info("Đã hết thời gian đăng ký");
                        } else {
                          setShowModalRegCompetition(true);
                          setIdCompetition(item);
                        }
                      }}
                      className="btnRes"
                    >
                      {t("text.Register")}
                    </button>
                  )}
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        wrapClassName="modal-cus modal-register-competition"
        title="Đăng ký tham gia"
        centered
        footer={null}
        onCancel={() => setShowModalRegCompetition(false)}
        visible={showModalRegCompetition}
      >
        <RegCompetition
          visible={setShowModalRegCompetition}
          idCompetition={idCompetition}
        />
      </Modal>
    </div>
  );
};

export default Participate;
