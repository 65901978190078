import { Button, Form, Input, Select, message } from 'antd';
import { useParams } from 'react-router-dom';
import { projectsApi } from '~/api/Projects/projectsApi';

const { Option } = Select;

const RegisterInvestmentForm = ({ setShowRegForm }) => {
    const { id } = useParams();
    let [formReg] = Form.useForm();

    const onSubmit = async (value) => {
        const dataSubmit = {
            ...value,
            project_id: parseInt(id),
        };

        const res = await projectsApi.createContributor(dataSubmit);
        if (res.full_name !== undefined) {
            message.success('Đăng ký thành công, vui lòng đợi doanh nghiệp liên hệ');
            formReg.resetFields();
            setShowRegForm(false);
        } else {
            message.error('Có lỗi xảy ra');
        }
    };

    return (
        <div className="register-investment-form-wrapper">
            <Form name="form-register-investment" layout="vertical" onFinish={onSubmit} form={formReg}>
                <Form.Item
                    name="full_name"
                    label="Họ và tên"
                    rules={[
                        {
                            required: true,
                            message: 'Họ và tên không được để trống',
                        },
                    ]}
                >
                    <Input placeholder="Nhập họ và tên" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Email không được để trống',
                        },
                        {
                            type: 'email',
                            message: 'Email không đúng định dạng',
                        },
                    ]}
                >
                    <Input placeholder="Nhập email" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Số điện thoại"
                    rules={[
                        {
                            required: true,
                            message: 'Số điện thoại không được để trống',
                        },
                        {
                            pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
                            message: 'Số điện thoại không đúng định dạng',
                        },
                    ]}
                >
                    <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Số tiền dự định đầu tư"
                    rules={[
                        {
                            required: true,
                            message: 'Số tiền không được để trống',
                        },
                    ]}
                >
                    <Select placeholder="Dự định đầu tư">
                        <Option value={1}>Từ 2 triệu đến 100 triệu đồng</Option>
                        <Option value={2}>Trên 100 triệu đồng</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                    <Input.TextArea placeholder="Nhập ghi chú" />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Gửi thông tin
                </Button>
            </Form>
        </div>
    );
};

export default RegisterInvestmentForm;
