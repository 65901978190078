import React from 'react';
import { Tabs } from 'antd';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderByStatus } from '~/store/Order/orderSlice';
import AllOrders from './AllOrders';
import './OrderManage.scss';

const OrderManage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listOrderByStatus } = useSelector((state) => state.orderReducer);

    const itemStatus = [
        {
            key: 1,
            label: t('text.Waiting_for_payment'),
            children: <AllOrders />,
        },
        {
            key: 2,
            label: t('text.Waiting_for_confirmation'),
            children: <AllOrders />,
        },
        {
            key: 4,
            label: t('text.Delivering'),
            children: <AllOrders />,
        },
        {
            key: 5,
            label: t('text.Delivered'),
            children: <AllOrders />,
        },
        {
            key: 6,
            label: t('text.Canceled'),
            children: <AllOrders />,
        },
    ];

    const handleChangeOrderStatus = (value) => {
        dispatch(
            getOrderByStatus({
                index: 1,
                size: 2,
                status: value,
            }),
        );
    };
    return (
        <div className="order-manage" id="order-wrapper">
            <div className="inner">
                <div className="header-main">
                    <div className="header-title">
                        <span>Đơn hàng</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span>Khóa học đã tham gia</span>
                    </div>
                    <div className="action-header">
                        <Tabs
                            onChange={(value) => {
                                handleChangeOrderStatus(value);
                            }}
                            defaultActiveKey="1"
                        >
                            {itemStatus.map((item) => {
                                return (
                                    <Tabs.TabPane tab={item.label} key={item.key}>
                                        <AllOrders listOrderByStatus={listOrderByStatus} />
                                    </Tabs.TabPane>
                                );
                            })}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderManage;
