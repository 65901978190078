import CreateCourse from '../CreateCourse';

const EditCourse = () => {
    return (
        <div>
            <CreateCourse isEdit={true} />
        </div>
    );
};

export default EditCourse;
