import axiosClient from '../axiosClient';

const enterpriseApi = {
    getAll(data) {
        let urlString = '';
        if (data === undefined) {
            urlString = '';
        } else {
            if (data.city_id !== '' && data.field_id === '' && data.name === '') {
                urlString = `?city_id=${data.city_id}`;
            }
            if (data.city_id === '' && data.field_id !== '' && data.name === '') {
                urlString = `?field_id=${data.field_id}`;
            }
            if (data.city_id === '' && data.field_id === '' && data.name !== '') {
                urlString = `?name=${data.name}`;
            }
            if (data.city_id !== '' && data.field_id !== '' && data.name === '') {
                urlString = `?city_id=${data.city_id}&field_id=${data.field_id}`;
            }
            if (data.city_id !== '' && data.field_id === '' && data.name !== '') {
                urlString = `?city_id=${data.city_id}&name=${data.name}`;
            }
            if (data.city_id === '' && data.field_id !== '' && data.name !== '') {
                urlString = `?field_id=${data.field_id}&name=${data.name}`;
            }
            if (data.city_id !== '' && data.field_id !== '' && data.name !== '') {
                urlString = `?city_id=${data.city_id}&field_id=${data.field_id}&name=${data.name}`;
            }
        }

        const url = `/enterprise${urlString}`;
        return axiosClient.get(url);
    },
    get(id) {
        const url = `/enterprise/${id}`;
        return axiosClient.get(url);
    },
    regContact(data) {
        const url = `/contacter/`;
        return axiosClient.post(url, data);
    },
    create(data) {
        const url = `/enterprise`;
        return axiosClient.post(url, data);
    },
    edit(data) {
        const url = `/enterprise/${data.id}`;
        return axiosClient.put(url, data.body);
    },
    delete(id) {
        const url = `/enterprise/${id}`;
        return axiosClient.delete(url);
    },
};
export default enterpriseApi;
