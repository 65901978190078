import { Col, Row } from 'antd';

import ShoppingMall from '../../introduce/ShoppingMall';
import './ListFair.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllEventsWithPag2 } from '~/store/Categories/eventSlice';

const ListFair = () => {
    const { eventListPag2 } = useSelector((state) => state.eventsReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getAllEventsWithPag2({
                type: 5,
                index: 1,
                size: 2,
            }),
        );
    }, []);

    return (
        <div className="list-fair-wrapper">
            <div className="list-fair-wrap">
                <Row gutter={[24, 24]}>
                    {eventListPag2.map((item, index) => {
                        return (
                            <Col span={8} style={{ width: '100%' }} key={index}>
                                <ShoppingMall item={item} />
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
};

export default ListFair;
