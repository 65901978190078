import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { getDocumentAllPaging } from "~/store/Documents/documentsSlice";
import images from "~/assets/images/home/header";
import styles from "./ListDocument.module.scss";
import "./ListDocument.scss";
import Document from "../..";
import { m } from "framer-motion";
import { useState } from "react";
import ListEventDocument from "./ListEventDocument";

// const cx = classNames.bind(styles);
function ListDocuments({ eventDocument }) {
  const [document, setDocument] = useState(0);
  // console.log(eventDocument);
  // console.log(eventDocument);
  // const dispatch = useDispatch();
  // const { documentsAllPaging } = useSelector((state) => state.documentsReducer);
  // useEffect(() => {
  //   dispatch(
  //     getDocumentAllPaging({
  //       index: 1,
  //       size: 10,
  //       type: 3,
  //     })
  //   );
  // }, [dispatch]);
  // console.log(documentsAllPaging);

  const BusinessTraining = [
    {
      image:
        "https://www.marinabaysands.com/content/dam/revamp/ASMrevamp/Seniors-void-deck/seniors-void-deck-desktop-1600x650.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://th.bing.com/th/id/R.3159aa80a9e97dd2f554fe445b0b821e?rik=3hE%2baL1IhOpUIg&pid=ImgRaw&r=0",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image: "https://i.ytimg.com/vi/gsgxAfl4tH0/maxresdefault.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://hoclamgiau.com.vn/wp-content/uploads/2019/11/kh%C3%B3a-h%E1%BB%8Dc-kh%E1%BB%9Fi-nghi%E1%BB%87p-kinh-doanh.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
  ];

  const PromotionProgram = [
    {
      image:
        "https://phunuvietnam.mediacdn.vn/thumb_w/700/179072216278405120/2022/10/17/le-viet-nga-16659807894101576480009.jpeg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://th.bing.com/th/id/R.9894c667ca48f32fa87357fd59cc7d05?rik=JPoeyM7eVutO1Q&riu=http%3a%2f%2fsan24h.vn%2fData%2fImages%2fArticle%2fD7BE2AA20C94452AB88775388BBC802D_t.jpg&ehk=ztP%2bm4UuaI5Wm1vg6qkdHqqpTFpsk%2bcM7W%2bgAQIRIOE%3d&risl=&pid=ImgRaw&r=0",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://phunuvietnam.mediacdn.vn/thumb_w/700/179072216278405120/2022/10/17/le-viet-nga-16659807894101576480009.jpeg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://cdnmedia.baotintuc.vn/Upload/DmtgOUlHWBO5POIHzIwr1A/files/2022/11/16/HTX-mien-trung-tay-nguyen-16112022-01.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
  ];

  const AnalysisReport = [
    {
      image:
        "https://www.marinabaysands.com/content/dam/revamp/ASMrevamp/Seniors-void-deck/seniors-void-deck-desktop-1600x650.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://www.marinabaysands.com/content/dam/revamp/ASMrevamp/Seniors-void-deck/seniors-void-deck-desktop-1600x650.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://www.marinabaysands.com/content/dam/revamp/ASMrevamp/Seniors-void-deck/seniors-void-deck-desktop-1600x650.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
    {
      image:
        "https://www.marinabaysands.com/content/dam/revamp/ASMrevamp/Seniors-void-deck/seniors-void-deck-desktop-1600x650.jpg",
      name: "Hồ sơ tài trợ Techfest 2022",
      time: "20/10/2022",
      view: "1000 lượt xem",
      dowload: "2000 lượt tải",
    },
  ];

  const [select, setSelect] = useState(1);
  const [color, setColor] = useState("");

  function selectBusinessTraining() {
    setSelect(1);
    // setColor(color)
  }

  function selectPromotionProgram() {
    setSelect(2);
    // setColor(color)
  }

  function selectAnalysisReport() {
    setSelect(3);
    // setColor(color)
  }

  return (
    <div className="select-container">
      <div className="select-event-document">
        <p
          onClick={selectBusinessTraining}
          style={{
            color: `${select === 1 ? "#019DB4" : "black"}`,
            borderBottom: `${select === 1 ? "3px solid" : "none"}`,
          }}
        >
          Đào tạo về kinh doanh
        </p>
        <p
          onClick={selectPromotionProgram}
          style={{
            color: `${select === 2 ? "#019DB4" : "black"}`,
            borderBottom: `${select === 2 ? "3px solid" : "none"}`,
          }}
        >
          Các chương trình xúc tiến
        </p>
        <p
          onClick={selectAnalysisReport}
          style={{
            color: `${select === 3 ? "#019DB4" : "black"}`,
            borderBottom: `${select === 3 ? "3px solid" : "none"}`,
          }}
        >
          Các báo cáo thống kê
        </p>
      </div>
      <div className="item-select">
        {select === 1 &&
          BusinessTraining.map((item, index) => (
            <ListEventDocument key={index} listData={item} />
          ))}
        {select === 2 &&
          PromotionProgram.map((item, index) => (
            <ListEventDocument key={index} listData={item} />
          ))}
        {select === 3 &&
          AnalysisReport.map((item, index) => (
            <ListEventDocument key={index} listData={item} />
          ))}
      </div>
    </div>
  );
}

export default ListDocuments;
