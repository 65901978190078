import React from 'react';
import CreateCourse from '~/layouts/AdminLayout/CoursesManage/CreateCourse';

const CreateCourseBusiness = () => {
    return (
        <div>
            <CreateCourse />
        </div>
    );
};

export default CreateCourseBusiness;
