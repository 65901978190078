import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import formatCash from '~/utils/formatCash';
import './PackageDetails.scss';

const PackageDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const packageDetail = localStorage.getItem('package') === null ? null : JSON.parse(localStorage.getItem('package'));

    return (
        <div className="package-details">
            <div className="top-content">
                <h1 className="package-title">Thông tin gói</h1>
            </div>
            <div className="bottom-content">
                <div className="row">
                    <label>Gói phí</label>
                    <div className="content-row">
                        <span>
                            Gói dịch vụ{' '}
                            {packageDetail?.cooperation_package?.time_limit === 30
                                ? '1 Tháng'
                                : packageDetail?.cooperation_package?.time_limit === 180
                                ? '6 Tháng'
                                : packageDetail?.cooperation_package?.time_limit === 360
                                ? '1 Năm'
                                : ''}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <label>Thông tin gói</label>
                    <div className="content-row">
                        <ul>
                            <li>{t('text.courses_manage')}</li>
                            <li>{t('text.Post_product_to_store')}</li>
                            <li>{t('text.Attract_investors')}</li>
                            <li>{t('text.Join_national_events')}</li>
                            <li>{t('text.Join_ConnectUp_training_courses')}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <label>Thông tin gói</label>
                    <div className="content-row">
                        <span className="status">Đang hoạt động</span>
                    </div>
                </div>
                <div className="row">
                    <label>Giá tiền</label>
                    <div className="content-row">
                        <span>{formatCash(packageDetail?.cooperation_package?.price?.toString())}đ</span>
                    </div>
                </div>
                <div className="row">
                    <label>Thao tác</label>
                    <div className="content-row">
                        <button
                            onClick={() => {
                                navigate('/activities?key=cooperate');
                            }}
                        >
                            Đổi gói
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackageDetails;
