import axiosClient from "../axiosClient";

const storeApi = {
  async getAllField() {
    const url = `/fields`;
    return axiosClient.get(url);
  },
  async getAllStoreByField(data) {
    const url = `/stores/all-paging?page_index=${data.index}&page_size=${data.size}`;
    return axiosClient.get(url);
  },
  async getAllStore() {
    const url = `/stores`;
    return axiosClient.get(url);
  },
  async getStoreAllPaging(data, body) {
    let urlString = "";
    if (body === undefined) {
      urlString = "";
    }
    if (body.city_id !== "" && body.field_id === "" && body.name === "") {
      urlString = `&city_id=${body.city_id}`;
    }
    if (body.city_id === "" && body.field_id !== "" && body.name === "") {
      urlString = `&field_id=${body.field_id}`;
    }
    if (body.city_id === "" && body.field_id === "" && body.name !== "") {
      urlString = `&name=${body.name}`;
    }
    if (body.city_id !== "" && body.field_id !== "" && body.name === "") {
      urlString = `&city_id=${body.city_id}&field_id=${body.field_id}`;
    }
    if (body.city_id !== "" && body.field_id === "" && body.name !== "") {
      urlString = `&city_id=${body.city_id}&name=${body.name}`;
    }
    if (body.city_id === "" && body.field_id !== "" && body.name !== "") {
      urlString = `&field_id=${body.field_id}&name=${body.name}`;
    }
    if (body.city_id !== "" && body.field_id !== "" && body.name !== "") {
      urlString = `&city_id=${body.city_id}&field_id=${body.field_id}&name=${body.name}`;
    }
    const url = `/stores/all-paging?page_index=${data.index}&page_size=${data.size}${urlString}`;
    return axiosClient.get(url);
  },
  async createField(data) {
    const url = `/fields`;
    return axiosClient.post(url, data);
  },
  async getFieldAllPaging(data) {
    const url = `/fields/all-paging?page_index=${data.index}&page_size=${data.size}`;
    return axiosClient.get(url);
  },
  async editField(data) {
    const url = `/fields/${data.id}`;
    return axiosClient.put(url, data.body);
  },
  async deleteField(data) {
    const url = `/fields/${data}`;
    return axiosClient.delete(url);
  },
  async getFieldById(data) {
    const url = `/fields/${data}`;
    return axiosClient.get(url);
  },
  async createStore(data) {
    const url = `/stores`;
    return axiosClient.post(url, data);
  },
  async editStore(data) {
    const url = `/stores/${data.id}`;
    return axiosClient.put(url, data.body);
  },
  async getMyStore() {
    const url = `/stores/my-store`;
    return axiosClient.get(url);
  },
  async getStoreById(data) {
    const url = `/stores/${data}`;
    return axiosClient.get(url);
  },
};

export default storeApi;
